import { Edit, useForm } from '@refinedev/antd'
import { IResourceComponentsProps, useList } from '@refinedev/core'
import { Form, Input, Select } from 'antd'

import { IGlobalActivity, IGlobalCategory } from 'interfaces'
import React from 'react'

export const GlobalActivitiesEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IGlobalActivity>({
    action: 'edit',
    redirect: 'show',
    resource: 'global-activities',
  })
    const { data: globalCategories } = useList<IGlobalCategory>({
        resource: 'global-categories',
    })

  return (
    <Edit canDelete resource="global-activities" saveButtonProps={saveButtonProps}>
      <Form
        layout="vertical"
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
            globalCategoryId: queryResult?.data?.data.globalCategoryId
        }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
          <Form.Item
              label="Global Category"
              name="globalCategoryId"
              rules={[{ message: 'Please select category', required: true }]}
          >
              <Select>
                  <Select.Option>
                      No category
                  </Select.Option>
                  {globalCategories?.data.map((item) => (
                      <Select.Option value={item.id}>
                          {item.name}
                      </Select.Option>
                  ))}
              </Select>
          </Form.Item>
      </Form>
    </Edit>
  )
}
