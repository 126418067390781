import { Breadcrumb, EditButton, Show, useForm } from '@refinedev/antd'
import { IResourceComponentsProps, useCreate, useDelete, useList } from '@refinedev/core'
import { useShow } from '@refinedev/core'
import { Button, Col, Form, List, Modal, Row, Select, Space, Table, Typography } from 'antd'

import { IOrganization, IUser } from 'interfaces'
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const data = useShow<IUser>()

  // 💿 DATA
  const record = data?.queryResult?.data?.data

  const {
    form: userOrganizationFormModal,
    formProps: userOrganizationFormModalProps,
  } = useForm<IUser>()
  const [isAddOrganizationsModalOpen, setIsAddOrganizationsModalOpen] =
      useState(false)
  const { mutate: mutateCreate } = useCreate()
  const { mutate: mutateDelete } = useDelete()
  const { data: organizations } = useList<IOrganization>({
    resource: 'organizations',
  })

  const disconnectOrganizationUser = async (organizationId: string) => {
    const userId = record?.id
    mutateDelete({
      resource: `users/${userId}/organizations/${organizationId}`,
      id: '',
      invalidates: ['all'],
    })
  }

  const connectOrganizationUser = () => {
    const organizationId = userOrganizationFormModal.getFieldValue(
        'selectedOrganizationId'
    )
    const role = userOrganizationFormModal.getFieldValue(
        'selectOrganizationRole'
    )
    const userId = record?.id

    mutateCreate({
      resource: `users/${userId}/organizations`,
      values: {
        organizationId,
        role,
      },
      invalidates: ['all'],
    })

    setIsAddOrganizationsModalOpen(false)
  }

  return (
      <>
    <Show
      breadcrumb={<Breadcrumb />}
      resource="users"
    >
      <Typography.Title level={3}>
        {record?.firstName} {record?.lastName}
      </Typography.Title>
      <Typography.Paragraph italic>Role:</Typography.Paragraph>
      <Typography.Paragraph>{record?.role}</Typography.Paragraph>
      <Typography.Paragraph italic>Email address:</Typography.Paragraph>
      <Typography.Paragraph>{record?.email}</Typography.Paragraph>
      <Typography.Paragraph italic>Phone number: </Typography.Paragraph>
      <Typography.Paragraph>{record?.phone}</Typography.Paragraph>
      <Typography.Paragraph italic>Skill level:</Typography.Paragraph>
      <Typography.Paragraph>{record?.skillLevel}</Typography.Paragraph>
      <EditButton recordItemId={record?.id} />
    </Show>
        <Row gutter={[16, 16]}>
          <Col lg={24} xl={24} xs={24}>
            <Typography.Title
                style={{
                  marginTop: '20px',
                }}
                level={4}
            >
              Admin's organizations:
            </Typography.Title>
            <Button
                type="primary"
                style={{
                  marginRight: '20px',
                  marginBottom: '20px',
                  marginTop: '10px',
                }}
                onClick={() => {
                  setIsAddOrganizationsModalOpen(true)
                }}
            >
              <PlusOutlined />
              Add new organization
            </Button>
            <List>
              <Table dataSource={record?.organizations} rowKey="id">
                <Table.Column title="Name" dataIndex="name" key="name" />
                <Table.Column
                    dataIndex="id"
                    title="Role"
                    render={(_, item: IOrganization) => {
                      return (
                          <Space>
                            {record?.organizationMemberships
                                ?.find(
                                    (membership) => membership.organizationId === item.id
                                )
                                ?.roles.reduce((acc, role) => {
                                  if (role.role) {
                                    return acc.concat(role.role)
                                  }

                                  return acc
                                }, [] as string[])
                                .join(', ')}
                          </Space>
                      )
                    }}
                />
                <Table.Column
                    dataIndex="id"
                    key="disconnect"
                    render={(_, record: IOrganization) => {
                      return (
                          <Button
                              danger
                              onClick={() => {
                                disconnectOrganizationUser(record.id)
                              }}
                              size="small"
                          >
                            Disconnect
                          </Button>
                      )
                    }}
                    title="Disconnect"
                />
              </Table>
            </List>
          </Col>
        </Row>

        {/* MODAL to add organization to an user */}
        <Modal
            width={800}
            title="Connect Organization with this user"
            open={isAddOrganizationsModalOpen}
            onOk={connectOrganizationUser}
            onCancel={() => {
              setIsAddOrganizationsModalOpen(false)
            }}
        >
          <Typography.Paragraph>Select an organization</Typography.Paragraph>
          <Form layout="vertical" {...userOrganizationFormModalProps}>
            <Form.Item
                required
                label="Organization"
                name="selectedOrganizationId"
            >
              <Select
                  placeholder="Select an organization"
                  title="Select an organization"
                  style={{ width: 400 }}
              >
                {organizations?.data.filter(
                    item => !record?.organizations?.some(org => org.id === item.id)
                ).map((organization) => (
                    <Select.Option key={organization.id} value={organization.id}>
                      {organization.name}
                    </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item required label="Role" name="selectOrganizationRole">
              <Select
                  placeholder="Select role"
                  title="Select role"
                  style={{ width: 400 }}
              >
                {['organization-user', 'organization-admin']?.map((role) => (
                    <Select.Option key={role} value={role}>
                      {role}
                    </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        </>
  )
}
