import React from 'react'
import {
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { List, useTable } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useDelete, useNavigation } from '@refinedev/core'
import { Dropdown, Menu, Table } from 'antd'

import type { ICategory } from '../../interfaces'

export const CategoriesList: React.FC<IResourceComponentsProps> = () => {
  const { edit, show } = useNavigation()

  const { tableProps } = useTable<ICategory>({
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  })

  const { mutate: mutateDelete } = useDelete()

  const moreMenu = (id: string) => (
    <Menu
      mode="vertical"
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      <Menu.Item
        icon={
          <EditOutlined
            style={{
              color: '#52c41a',
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        key="edit"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          edit('categories', id)
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        icon={
          <CloseCircleOutlined
            style={{
              color: '#EE2A1E',
              fontSize: 17,
            }}
          />
        }
        key="delete"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          mutateDelete({
            id,
            mutationMode: 'undoable',
            resource: 'categories',
          })
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="name" title="Name" />
          <Table.Column
              title="Global Category"
              render={(_, record: ICategory) => record.globalCategory?.name || '-'}
          />
          <Table.Column
              title="Organization"
              render={(_, record: ICategory) => record.organization?.name || '-'}
          />
        <Table.Column<ICategory>
          dataIndex="actions"
          fixed="right"
          key="actions"
          render={(_, record: ICategory) => {
              console.log('record', record)
              return (
                  <Dropdown overlay={moreMenu(record.id)} trigger={['click']}>
                      <MoreOutlined
                          style={{ fontSize: 24 }}
                          onClick={(e) => e.stopPropagation()}
                      />
                  </Dropdown>
              )
          }}
          title="Actions"
        />
      </Table>
    </List>
  )
}
