import {
  BarsOutlined,
  DashboardOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import type { RefineLayoutSiderProps } from '@refinedev/antd'
import type { ITreeMenu } from '@refinedev/core'
import {
  CanAccess,
  useIsExistAuthentication,
  useLogout,
  useMenu,
  useRefineContext,
  useTranslate,
} from '@refinedev/core'
import { useRouterContext } from '@refinedev/core'
import { Button, ConfigProvider, Drawer, Grid, Layout, Menu } from 'antd'
import React, { useState } from 'react'

import { drawerButtonStyles } from './styles'

const { SubMenu } = Menu

const Logo = ({ collapsed }: { collapsed: boolean }) => {
  if (collapsed) {
    return (
      <svg
        fill="none"
        style={{ padding: '8px 24px', maxWidth: '100%' }}
        viewBox="0 0 75 104"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0h30.302v103.809H0V0ZM74.374 21.051c0 11.627-9.5 21.052-21.218 21.052S31.938 32.677 31.938 21.05C31.938 9.425 41.438 0 53.156 0s21.218 9.425 21.218 21.051Z"
          fill="#fff"
        />
      </svg>
    )
  }

  return (
    <svg
      fill="none"
      style={{ padding: '16px 32px', maxWidth: '100%' }}
      viewBox="0 0 252 104"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124.831 79.337c-11.537 0-21.284-7.105-24.267-19.34h73.399C175.952 25.656 156.658 0 124.036 0c-27.45 0-51.52 21.907-51.52 51.904 0 29.801 24.07 51.905 52.315 51.905 19.295 0 35.805-8.684 45.353-24.275L148.9 64.93c-5.768 8.88-12.333 14.407-24.069 14.407Zm-.596-55.062c12.929 0 19.692 8.486 21.085 16.972h-43.762c3.581-10.657 12.532-16.972 22.677-16.972ZM231.114 0c-28.644 0-53.309 21.907-53.309 51.904 0 29.998 24.665 51.905 53.309 51.905 7.957 0 14.521-1.382 20.886-3.947V70.653c-5.172 3.947-10.94 6.71-19.295 6.71-13.725 0-25.859-10.065-25.859-25.459 0-15.393 12.134-25.458 25.859-25.458 8.355 0 14.123 2.565 19.096 6.71V3.947C245.635 1.381 239.071 0 231.114 0ZM0 0h30.302v103.809H0V0ZM74.374 21.051c0 11.627-9.5 21.052-21.218 21.052S31.938 32.677 31.938 21.05C31.938 9.425 41.438 0 53.156 0s21.218 9.425 21.218 21.051Z"
        fill="#fff"
      />
    </svg>
  )
}

export const Sider: React.FC<RefineLayoutSiderProps> = ({ render }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const isExistAuthentication = useIsExistAuthentication()
  const { Link } = useRouterContext()

  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: true,
  })

  const translate = useTranslate()
  const { defaultOpenKeys, menuItems, selectedKey } = useMenu()
  const breakpoint = Grid.useBreakpoint()
  const { hasDashboard } = useRefineContext()

  const isMobile = typeof breakpoint.lg === 'undefined' ? false : !breakpoint.lg

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { children, icon, label, name, parentName, route } = item

      if (children.length > 0) {
        return (
          <CanAccess
            action="list"
            key={route}
            params={{
              resource: item,
            }}
            resource={name.toLowerCase()}
          >
            <SubMenu
              icon={icon ?? <UnorderedListOutlined />}
              key={route}
              title={label}
            >
              {renderTreeView(children, selectedKey)}
            </SubMenu>
          </CanAccess>
        )
      }

      const isSelected = route === selectedKey
      const isRoute = !(parentName !== undefined && children.length === 0)

      return (
        <CanAccess
          action="list"
          key={route}
          params={{
            resource: item,
          }}
          resource={name.toLowerCase()}
        >
          <Menu.Item
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
            key={route}
            style={{
              fontWeight: isSelected ? 'bold' : 'normal',
            }}
          >
            <Link to={route}>{label}</Link>
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      )
    })
  }

  const logout = isExistAuthentication && (
    <Menu.Item
      icon={<LogoutOutlined />}
      key="logout"
      onClick={() => mutateLogout()}
    >
      {translate('buttons.logout', 'Logout')}
    </Menu.Item>
  )

  const dashboard = hasDashboard ? (
    <Menu.Item
      icon={<DashboardOutlined />}
      key="dashboard"
      style={{
        fontWeight: selectedKey === '/' ? 'bold' : 'normal',
      }}
    >
      <Link to="/">{translate('dashboard.title', 'Dashboard')}</Link>
      {!collapsed && selectedKey === '/' && (
        <div className="ant-menu-tree-arrow" />
      )}
    </Menu.Item>
  ) : null

  const items = renderTreeView(menuItems, selectedKey)

  const renderSider = () => {
    if (render) {
      return render({
        collapsed,
        dashboard,
        items,
        logout,
      })
    }

    return (
      <>
        {dashboard}
        {items}
        {logout}
      </>
    )
  }

  const renderMenu = () => {
    return (
      <Menu
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        selectedKeys={[selectedKey]}
        theme="dark"
        onClick={() => {
          setDrawerOpen(false)

          if (!breakpoint.lg) {
            setCollapsed(true)
          }
        }}
      >
        {renderSider()}
      </Menu>
    )
  }

  const renderDrawerSider = () => {
    return (
      <>
        <Drawer
          maskClosable
          bodyStyle={{
            padding: 0,
          }}
          closable={false}
          open={drawerOpen}
          placement="left"
          width={200}
          onClose={() => setDrawerOpen(false)}
        >
          <Layout>
            <Layout.Sider style={{ height: '100vh', overflow: 'hidden' }}>
              <Link to="/">
                <Logo collapsed={collapsed} />
              </Link>
              {renderMenu()}
            </Layout.Sider>
          </Layout>
        </Drawer>
        <Button
          icon={<BarsOutlined />}
          size="large"
          style={drawerButtonStyles}
          onClick={() => setDrawerOpen(true)}
        />
      </>
    )
  }

  const renderContent = () => {
    if (isMobile) {
      return renderDrawerSider()
    }

    return (
      <Layout.Sider
        collapsible
        breakpoint="lg"
        collapsed={collapsed}
        collapsedWidth={80}
        onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      >
        <Link to="/">
          <Logo collapsed={collapsed} />
        </Link>
        {renderMenu()}
      </Layout.Sider>
    )
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            colorItemBg: 'transparent',
            colorItemBgSelected: 'transparent',
            colorItemText: '#fff',
            colorItemTextHover: '#fff',
            colorItemTextSelected: '#fff',
          },
        },
      }}
    >
      {renderContent()}
    </ConfigProvider>
  )
}
