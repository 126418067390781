import React from 'react'
import { Breadcrumb, Create, useForm } from '@refinedev/antd'
import { IResourceComponentsProps, useList } from '@refinedev/core'
import { Form, Input, Select } from 'antd'
import { ICategory, IGlobalCategory, IOrganization } from 'interfaces'

export const CategoriesCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ICategory>()
  const { data: organizations } = useList<IOrganization>({
    resource: 'organizations',
  })
  const { data: globalCategories } = useList<IGlobalCategory>({
    resource: 'global-categories',
  })

  return (
    <Create
      breadcrumb={<Breadcrumb />}
      resource="categories"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
            label="Organization"
            name="organizationId"
            rules={[{ message: 'Please select organization', required: true }]}
        >
          <Select>
            {organizations?.data.map((organization) => (
                <Select.Option value={organization.id}>
                  {organization.name}
                </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
            label="Global Category"
            name="globalCategoryId"
            rules={[{ message: 'Please select category', required: true }]}
        >
          <Select>
            {globalCategories?.data.map((item) => (
                <Select.Option value={item.id}>
                  {item.name}
                </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Create>
  )
}
