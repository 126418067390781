import { Edit, useForm } from '@refinedev/antd'
import { IResourceComponentsProps, useList } from '@refinedev/core'
import { Form, Input, Select } from 'antd'

import { IActivity, ICategory, IGlobalActivity, IOrganization } from 'interfaces'
import React, { useCallback, useEffect } from 'react'

export const ActivitiesEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IActivity>({
    action: 'edit',
    redirect: 'show',
    resource: 'activities',
  })
    const { data: organizations } = useList<IOrganization>({
        resource: 'organizations',
    })
    const { data: categories } = useList<ICategory>({
        resource: 'categories',
    })
    const { data: globalActivities } = useList<IGlobalActivity>({
        resource: 'global-activities',
    })

    const [selectedOrgId, setSelectedOrg] = React.useState<string | undefined>(queryResult?.data?.data.organizationId)
    const handleOrgChange = useCallback(
        (value: string) => {
            setSelectedOrg(value)
            formProps.form?.setFieldsValue({ categoryId: '' })
        },
        [formProps.form]
    )

    useEffect(() => {
        if(queryResult?.data?.data.organizationId) {
            setSelectedOrg(queryResult?.data?.data.organizationId)
        }
    }, [queryResult?.data?.data.organizationId]);

  return (
    <Edit canDelete resource="activities" saveButtonProps={saveButtonProps}>
      <Form
        layout="vertical"
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
            categoryId: queryResult?.data?.data.categoryId,
            globalActivityId: queryResult?.data?.data.globalActivityId,
            organizationId: queryResult?.data?.data.organizationId,
        }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
          <Form.Item
              label="Organization"
              name="organizationId"
              rules={[{ message: 'Please select organization', required: true }]}
          >
              <Select onChange={handleOrgChange}>
                  <Select.Option value={undefined}>
                      No organization
                  </Select.Option>
                  {organizations?.data.map((item) => (
                      <Select.Option value={item.id}>
                          {item.name}
                      </Select.Option>
                  ))}
              </Select>
          </Form.Item>
          <Form.Item
              label="Category Id"
              name="categoryId"
              rules={[{ message: 'Please select category', required: true }]}
          >
              <Select>
                  <Select.Option value={undefined}>
                      No category
                  </Select.Option>
                  {categories
                      ?.data
                      ?.filter((item) => {
                        const organizationId = selectedOrgId
                        return organizationId
                            ? item.organizationId === organizationId
                            : true
                      })
                      .map((item) => (
                      <Select.Option value={item.id}>
                          {item.name}
                      </Select.Option>
                  ))}
              </Select>
          </Form.Item>
          <Form.Item
              label="Global Activity"
              name="globalActivityId"
              rules={[{ message: 'Please select global activity', required: true }]}
          >
              <Select>
                  <Select.Option value={undefined}>
                      No activity
                  </Select.Option>
                  {globalActivities?.data.map((item) => (
                      <Select.Option value={item.id}>
                          {item.name}
                      </Select.Option>
                  ))}
              </Select>
          </Form.Item>
      </Form>
    </Edit>
  )
}
