import { Breadcrumb, Edit, useForm } from '@refinedev/antd'
import { useList, type IResourceComponentsProps } from '@refinedev/core'
import { Form, Input, Select } from 'antd'

import type { IGroup, IOrganization } from 'interfaces'

export const GroupsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, queryResult, saveButtonProps } = useForm<IGroup>({
    action: 'edit',
    redirect: 'show',
    resource: 'groups',
  })

  // fetch organizations to select from
  const { data: organizations } = useList<IOrganization>({
    resource: 'organizations',
  })

  return (
    <Edit
      canDelete
      breadcrumb={<Breadcrumb />}
      resource="groups"
      saveButtonProps={saveButtonProps}
    >
      <Form layout="vertical" {...formProps}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ message: 'Please enter a name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ message: 'Please enter a description' }]}
        >
          <Input.TextArea allowClear />
        </Form.Item>
        <Form.Item
          label="Group Type"
          name="groupType"
          rules={[{ message: 'Please enter group type' }]}
        >
          <Select>
            <Select.Option value="residency">residency</Select.Option>
            <Select.Option value="special-group">special-group</Select.Option>
            <Select.Option value="for-purchase">for-purchase</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Eligibility"
          name="eligibility"
          rules={[{ message: 'Please enter a group eligibility' }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Join Instructions"
          name="joinInstructions"
          rules={[{ message: 'Please enter a group join instructions' }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Benefits"
          name="benefit"
          rules={[{ message: 'Please enter a group benefits' }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Organization" name="organizationId">
          <Select allowClear>
            {organizations?.data.map((organization: IOrganization) => (
              <Select.Option key={organization.id} value={organization.id}>
                {organization.name}
              </Select.Option>
            ))}
            <Select.Option key="null" value={null}>
              None
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Edit>
  )
}
