import type { LegacyAuthProvider } from '@refinedev/core'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'

import { firebaseAuth } from 'auth/auth'

export const ACCESS_TOKEN_KEY = 'access_token'

export const getAccessToken = async () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)

  return { accessToken }
}

export const setAccessToken = async (accessToken?: string) => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
  } else {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
  }
}

const getMe = async ({ token }: { token: string }) => {
  const me = await fetch(`${process.env.REACT_APP_API_URL}/v1/users/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'GET',
  }).then(async (res) => await res.json())

  return me
}

const isUserAdmin = async ({ token }: { token: string | null }) => {
  if (!token) {
    return false
  }

  const me = await getMe({ token })

  return me.role === 'admin'
}

export const authProvider: LegacyAuthProvider = {
  checkAuth: async () => {
    const { accessToken } = await getAccessToken()
    const isAdmin = await isUserAdmin({ token: accessToken })

    if (accessToken && isAdmin) {
      return await Promise.resolve()
    }

    return await Promise.reject(new Error())
  },

  checkError: async () => await Promise.resolve(),
  login: async ({ email, password }) => {
    if (!email || !password) {
      return await Promise.reject('Email and password are required.')
    }

    try {
      const userCredential = await signInWithEmailAndPassword(
        firebaseAuth,
        email,
        password
      )

      const token = await userCredential.user.getIdToken()
      const isAdmin = await isUserAdmin({ token })

      if (isAdmin) {
        setAccessToken(token)
        return await Promise.resolve()
      } else {
        return await Promise.reject('You are not authorized to login')
      }
    } catch (error) {
      return await Promise.reject('Invalid email or password.')
    }
  },
  logout: async () => {
    try {
      signOut(firebaseAuth)

      return await Promise.resolve()
    } catch (error) {
      return await Promise.reject(error)
    }
  },
}
