import { CrudFilters } from '@refinedev/core'
import { Button, Col, Form, FormProps, Row, Select } from 'antd'
import { Instructor } from 'interfaces'
import React from 'react'
import { ClassType, getValuesOf } from 'utils/enums'
import { getInstructorName } from '../utils/getInstructorName'

export const Filter: React.FC<{
  formProps: FormProps
  instructors: Instructor[]
}> = ({ formProps, instructors }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={6} xl={6} md={8}>
          <Form.Item label="Class type" name="type">
            <Select
              allowClear
              placeholder="Select class type"
              options={getValuesOf(ClassType)?.map((value) => ({
                label: value,
                value,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={6} xl={6} md={8}>
          <Form.Item label="Instructor" name="instructor">
            <Select allowClear placeholder="Search user email" showSearch>
              {instructors
                ?.sort(
                  (a, b) =>
                    a.user?.email.localeCompare(b.user?.email ?? '') ?? 0
                )
                .map((instructor) => (
                  <Select.Option key={instructor.id} value={instructor.id}>
                    {getInstructorName(instructor)}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export type FilterValues = {
  type?: ClassType
  instructor?: string
}

export const onSearch = async (params: FilterValues) => {
  const filters: CrudFilters = []
  const { type, instructor } = params

  filters.push({
    field: 'type',
    operator: 'eq',
    value: type,
  })

  filters.push({
    field: 'instructorId',
    operator: 'eq',
    value: instructor,
  })

  return filters
}
