import React from 'react'
import { Breadcrumb, Create, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { Form, Input } from 'antd'
import type { IGlobalCategory } from 'interfaces'

export const GlobalCategoriesCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IGlobalCategory>()

  return (
    <Create
      breadcrumb={<Breadcrumb />}
      resource="global-categories"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Create>
  )
}
