import { PlusOutlined } from '@ant-design/icons'
import { Edit, getValueFromEvent, useForm } from '@refinedev/antd'
import { useCustomMutation, useList, useUpdate } from '@refinedev/core'
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Upload,
} from 'antd'
import type {
  ICourtResponse,
  ILocation,
  Instructor,
  IPreSignedRequest,
  IPreSignedResponse,
  ISport,
} from 'interfaces'
import { useEffect, useMemo, useState } from 'react'

export const InstructorsEdit: React.FC = () => {
  // 🪝HOOKS:
  const { form, formProps, queryResult, saveButtonProps } =
    useForm<Instructor>()
  const [tagInput, setTagInput] = useState<string>('')
  const [tags, setTags] = useState<string[]>([])
  const { mutate } = useUpdate<Instructor>({})
  const { mutateAsync } = useCustomMutation<IPreSignedResponse>()
  const [selectedLocation, setSelectedLocation] = useState()

  const { data: locations } = useList<ILocation>({
    resource: 'locations',
  })

  const { data: courts } = useList<ICourtResponse>({
    queryOptions: {
      enabled: !!selectedLocation,
      queryKey: ['list', { locationId: selectedLocation }],
    },
    resource: `courts?locationId=${selectedLocation}`,
  })

  const { data: sports } = useList<ISport>({
    resource: 'sports',
  })

  const sportOptions = useMemo(() => {
    const locationSportIds = courts?.data
      .map((court) => {
        return court.sports.map((sport) => {
          return sport.sportId
        })
      })
      .reduce((acc, val) => {
        return [...acc, ...val]
      }, [])

    const instructorSportIds = queryResult?.data?.data.sports?.map((sport) => {
      return sport.id
    })

    const uniqueLocationSportIds = [
      ...new Set([...(locationSportIds ?? []), ...(instructorSportIds ?? [])]),
    ]

    return uniqueLocationSportIds
      .map((sportId) => {
        return sports?.data.find((sport) => sport.id === sportId)
      })
      .filter((sport) => {
        if (!sport?.id) {
          return false
        }
        return instructorSportIds?.includes(sport.id)
      })
  }, [courts, queryResult])

  //🚀 FUNCTIONS:
  // use useEffect to set tags from query result
  useEffect(() => {
    if (queryResult?.data?.data?.tags) {
      setTags(queryResult?.data?.data?.tags)
    }
  }, [])

  const removeItemFromTags = (item: string) => {
    setTags(tags.filter((tag) => tag !== item))
  }

  const addItemToTags = (item: string) => {
    setTags([...tags, item])
  }

  const handleSubmit = (values: any) => {
    const { instructorLocations, ...rest } = values as Instructor
    const sanitizedInstructorLocations = instructorLocations.map((location) => {
      const { sportId, hourlyRate, locationId } = location
      return {
        hourlyRate: Number(hourlyRate),
        locationId,
        sportId,
      }
    })

    const sanitizedValues = {
      ...rest,
      instructorLocations: sanitizedInstructorLocations,
      tags,
    }

    if (queryResult?.data?.data?.id) {
      mutate(
        {
          id: queryResult?.data?.data?.id,
          invalidates: ['all'],
          resource: 'instructors',
          values: sanitizedValues,
        },
        {
          onSuccess: () => {
            form.resetFields()
          },
        }
      )
    }
  }

  const getUploadAction = async (
    args: File,
    type:
      | 'instructorAvatar80x80'
      | 'instructor240x140'
      | 'instructor152x89'
      | 'instructor152x240'
  ) => {
    let { name: key, type: contentType } = args

    // make sure we always use jpeg, as content type for .jpg is image/jpeg
    if (key.split('.').pop() === 'jpg') key = key.replace('jpg', 'jpeg')

    const values: IPreSignedRequest = {
      contentType,
      key,
      type,
    }

    const url = `instructors/${queryResult?.data?.data.id}/image`

    const { data } = await mutateAsync({
      method: 'post',
      url,
      values,
    })

    return data.preSignedUrl
  }

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      canDelete
      deleteButtonProps={{
        confirmTitle:
          'All the classes related to the instructor and their images will be permanently deleted from database. Do you wish to proceed?',
      }}
    >
      <Form {...formProps} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Short description"
          name="shortDescription"
          rules={[
            { message: 'Please enter a short description', required: true },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Bio"
          name="longBio"
          rules={[{ message: 'Please enter a bio', required: true }]}
        >
          <Input.TextArea />
        </Form.Item>
        {/* TODO - delete this once API is changed so userId is not required in the patch request */}
        <Form.Item
          hidden
          label="userId"
          name="userId"
          rules={[{ message: 'Please enter a userId', required: true }]}
        >
          <Input type="" value={queryResult?.data?.data?.userId} />
        </Form.Item>
        <Form.Item label="Tags (Press enter to add a new tag)" name="tags">
          <Input
            value={tagInput}
            onChange={(e) => setTagInput(e.currentTarget.value)}
            onPressEnter={(e) => {
              addItemToTags(e.currentTarget.value)
              setTagInput('')
            }}
          />
          {tags?.map((tag) => (
            <Tag
              closable
              onClose={() => removeItemFromTags(tag)}
              style={{ marginTop: 10 }}
            >
              {tag}
            </Tag>
          ))}
        </Form.Item>
        <Form.List name="instructorLocations">
          {(locationFields, { add, remove }) => {
            return (
              <Space direction="vertical" style={{ width: '100%' }}>
                {locationFields.map((locationField) => (
                  <Row key={locationField.key} gutter={8} justify="center">
                    <Col span={6}>
                      <Form.Item
                        {...locationField}
                        label="Location"
                        name={[locationField.name, 'locationId']}
                      >
                        <Select
                          allowClear
                          onChange={(id) => {
                            setSelectedLocation(id)
                          }}
                        >
                          {locations?.data
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((location) => (
                              <Select.Option
                                value={location?.id}
                                key={location?.id}
                              >
                                {location.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...locationField}
                        label="Sport"
                        name={[locationField.name, 'sportId']}
                      >
                        <Select>
                          {sportOptions.map((sport) => (
                            <Select.Option value={sport?.id} key={sport?.id}>
                              {sport?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...locationField}
                        label="Hourly Rate"
                        name={[locationField.name, 'hourlyRate']}
                      >
                        <Input type="number" />
                      </Form.Item>
                    </Col>
                    <Col
                      span={6}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        danger
                        size="small"
                        onClick={() => remove(locationField.name)}
                        style={{
                          minHeight: '32px',
                          marginTop: '6px',
                        }}
                      >
                        Remove location
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    block
                    icon={<PlusOutlined />}
                    type="dashed"
                    onClick={() => add()}
                  >
                    Add location
                  </Button>
                </Form.Item>
              </Space>
            )
          }}
        </Form.List>
        <Row gutter={8} justify="start">
          <Col span={4}>
            <Form.Item
              label="Can teach private lessons"
              name="canTeachPrivateLessons"
            >
              <Switch
                defaultChecked={
                  queryResult?.data?.data?.canTeachPrivateLessons ?? false
                }
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Do not market for lessons"
              name={["config", "doNotMarketForLessons"]}
            >
              <Switch
                defaultChecked={
                  queryResult?.data?.data?.config.doNotMarketForLessons ?? false
                }
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Offers lesson packs"
              name={["config", "offersLessonPacks"]}
            >
              <Switch
                defaultChecked={
                  queryResult?.data?.data?.config.offersLessonPacks ?? false
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Form.Item
            noStyle
            getValueFromEvent={getValueFromEvent}
            valuePropName="fileList"
          >
            <Upload.Dragger
              action={(args) => getUploadAction(args, 'instructorAvatar80x80')}
              customRequest={(args) => {
                const formData = new FormData()
                formData.append('File', args.file)
                const file = formData.get('File')

                const xhr = new XMLHttpRequest()
                xhr.open('PUT', args.action)
                xhr.send(file)
              }}
              listType="picture"
              maxCount={1}
              method="PUT"
              name="file"
              style={{
                background: 'none',
                border: 'none',
                width: '100%',
              }}
            >
              <Space direction="vertical" size={2}>
                <div>instructorAvatar80x80 image</div>
                <Image
                  alt="Facility Location"
                  src={
                    queryResult?.data?.data.images?.instructorAvatar80x80 ??
                    '/images/user-default-img.png'
                  }
                  style={{
                    height: '100%',
                    maxWidth: '200px',
                    width: '100%',
                  }}
                />
              </Space>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item
            noStyle
            getValueFromEvent={getValueFromEvent}
            valuePropName="fileList"
          >
            <Upload.Dragger
              action={(args) => getUploadAction(args, 'instructor240x140')}
              customRequest={(args) => {
                const formData = new FormData()
                formData.append('File', args.file)
                const file = formData.get('File')

                const xhr = new XMLHttpRequest()
                xhr.open('PUT', args.action)
                xhr.send(file)
              }}
              listType="picture"
              maxCount={1}
              method="PUT"
              name="file"
              style={{
                background: 'none',
                border: 'none',
                width: '100%',
              }}
            >
              <Space direction="vertical" size={2}>
                <div>instructor240x140 image</div>
                <Image
                  alt="Facility Location"
                  src={
                    queryResult?.data?.data.images?.instructor240x140 ??
                    '/images/user-default-img.png'
                  }
                  style={{
                    height: '100%',
                    maxWidth: '200px',
                    width: '100%',
                  }}
                />
              </Space>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item
            noStyle
            getValueFromEvent={getValueFromEvent}
            valuePropName="fileList"
          >
            <Upload.Dragger
              action={(args) => getUploadAction(args, 'instructor152x89')}
              customRequest={(args) => {
                const formData = new FormData()
                formData.append('File', args.file)
                const file = formData.get('File')

                const xhr = new XMLHttpRequest()
                xhr.open('PUT', args.action)
                xhr.send(file)
              }}
              listType="picture"
              maxCount={1}
              method="PUT"
              name="file"
              style={{
                background: 'none',
                border: 'none',
                width: '100%',
              }}
            >
              <Space direction="vertical" size={2}>
                <div>instructor152x89 image</div>
                <Image
                  alt="Facility Location"
                  src={
                    queryResult?.data?.data.images?.instructor152x89 ??
                    '/images/user-default-img.png'
                  }
                  style={{
                    height: '100%',
                    maxWidth: '200px',
                    width: '100%',
                  }}
                />
              </Space>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Form.Item
            noStyle
            getValueFromEvent={getValueFromEvent}
            valuePropName="fileList"
          >
            <Upload.Dragger
              action={(args) => getUploadAction(args, 'instructor152x240')}
              customRequest={(args) => {
                const formData = new FormData()
                formData.append('File', args.file)
                const file = formData.get('File')

                const xhr = new XMLHttpRequest()
                xhr.open('PUT', args.action)
                xhr.send(file)
              }}
              listType="picture"
              maxCount={1}
              method="PUT"
              name="file"
              style={{
                background: 'none',
                border: 'none',
                width: '100%',
              }}
            >
              <Space direction="vertical" size={2}>
                <div>instructor152x240 image</div>
                <Image
                  alt="Facility Location"
                  src={
                    queryResult?.data?.data.images?.instructor152x240 ??
                    '/images/user-default-img.png'
                  }
                  style={{
                    height: '100%',
                    maxWidth: '200px',
                    width: '100%',
                  }}
                />
              </Space>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Form>
    </Edit>
  )
}
