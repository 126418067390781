import { BaseRecord, useNavigation, useOne } from '@refinedev/core'
import { Tooltip } from 'antd'
import type { ComponentProps } from 'react'
import { useEffect } from 'react'

type Props<TData extends BaseRecord = BaseRecord> = ComponentProps<'a'> & {
  resource: string
  id: string
  value: string
  valueTransform?: (data: TData | undefined) => string | undefined
}

export function RelationField<TData extends BaseRecord = BaseRecord>({
  resource,
  id,
  value,
  valueTransform,
  ...props
}: Props<TData>) {
  const { show } = useNavigation()
  const { data, refetch, isLoading, isError } = useOne<TData>({
    resource,
    id,
    queryOptions: {
      enabled: !!valueTransform,
    },
  })
  useEffect(() => {
    if (valueTransform) {
      void refetch()
    }
  }, [valueTransform, refetch])
  const text =
    !!valueTransform && !isLoading && !isError
      ? valueTransform?.(data?.data)
      : value

  return (
    <Tooltip title="Show detail">
      <a {...props} onClick={() => show(resource, id)}>
        {text}
      </a>
    </Tooltip>
  )
}
