import { Collapse } from 'antd'
import React from 'react'

type Props = {
  children: React.ReactNode
}

function FilterAccordion({ children }: Props) {
  return (
    <Collapse style={{ marginBottom: '0.5rem' }}>
      <Collapse.Panel header="Filters" key="0">
        {children}
      </Collapse.Panel>
    </Collapse>
  )
}

export default FilterAccordion
