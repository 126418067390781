import { useForm } from '@refinedev/antd'
import { useCreate, useInvalidate, useList } from '@refinedev/core'
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from 'antd'
import { ActionsWrapper } from 'components/locations/courts/actionsWrapper'
import dayjs from 'dayjs'
import { IClass, ICourtResponse, ILocation, ISport } from 'interfaces'
import React, { FC, useMemo, useState } from 'react'

export const CreateRecurringReservations: FC<{
  classData: IClass
}> = ({ classData }) => {
  const invalidate = useInvalidate()
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(
    classData.locationId
  )
  const { formProps: reservationFormProps, form: reservationForm } = useForm()
  const { mutate: createReservation } = useCreate()
  const [slotCount, setSlotCount] = useState(1)

  // fetch locations to select from
  const { data: locations } = useList<ILocation>({
    resource: 'locations',
  })

  // fetch courts to select from
  const { data: courts } = useList<ICourtResponse>({
    queryOptions: {
      enabled: !!selectedLocationId,
      queryKey: ['list', { locationId: selectedLocationId }],
    },

    resource: `courts?locationId=${selectedLocationId}`,
  })

  const { data: sports } = useList<ISport>({
    resource: 'sports',
  })

  // fetch all groups for select options
  const { data: groups } = useList({
    resource: 'groups',
  })

  const courtSports = useMemo(() => {
    const courtsData = courts?.data ?? []

    const filteredBySport = courtsData
      .flatMap((court) => court.sports)
      .filter((courtSport) => courtSport.sportId === classData.sportId)

    return filteredBySport
  }, [courts])

  const getCourts = (locationId: string) => {
    if (locationId) {
      setSelectedLocationId(locationId)

      invalidate({
        invalidates: ['list'],
        resource: 'courts',
      })
    }
  }

  // update handleReservation:
  const handleReservation = () => {
    const formValues: any = reservationFormProps.form?.getFieldsValue()

    const request = Array.from({ length: slotCount }).map((_, index) => {
      const date = formValues[`date[${index}]`]

      if (!date) {
        return null // Skip the iteration if date is undefined
      }

      const startDate = dayjs(date[0].$d).format('YYYY-MM-DD')
      const startTime = dayjs(date[0].$d).format('HH:mm:00')
      const endDate = dayjs(date[1].$d).format('YYYY-MM-DD')
      const endTime = dayjs(date[1].$d).format('HH:mm:00')

      return {
        courtSportIds: formValues[`courtSportId[${index}]`],
        classId: formValues?.classId,
        capacity: formValues?.capacity,
        from: {
          date: startDate,
          time: startTime,
        },
        to: {
          date: endDate,
          time: endTime,
        },
        groupsOnly: formValues?.groupsOnly,
      }
    })

    createReservation(
      {
        resource: 'reservations/linked',
        values: request,
      },
      {
        onSuccess: () => {
          reservationForm.resetFields()
        },
      }
    )
  }
  return (
    <Card
      title="Create Recurring Reservations"
      actions={[
        <ActionsWrapper>
          <Button type="primary" onClick={handleReservation}>
            Create
          </Button>
        </ActionsWrapper>,
      ]}
    >
      <Form layout="vertical" {...reservationFormProps}>
        <Form.Item
          label="Class"
          name="classId"
          initialValue={classData?.id}
          hidden
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Location"
          name="locationId"
          rules={[{ message: 'Please enter a location' }, { required: true }]}
        >
          <Select allowClear onChange={getCourts}>
            {locations?.data.map((location) => (
              <Select.Option value={location.id} key={location.id}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Capacity" name="capacity">
          <InputNumber />
        </Form.Item>

        <Form.Item label="Restrict only to group members" name="groupsOnly">
          <Select allowClear mode="multiple" style={{ maxWidth: 300 }}>
            {groups?.data.map((group) => (
              <Select.Option value={group.id} key={group.id}>
                {group.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <hr />

        {Array.from({ length: slotCount }).map((_, index) => (
          <React.Fragment key={index}>
            <Typography.Title level={5}>Session #{index + 1}</Typography.Title>
            <Form.Item label="Court & Sport" name={`courtSportId[${index}]`}>
              <Select mode="multiple" allowClear>
                {courtSports?.map((courtSport) => {
                  const sportsData = sports?.data ?? []
                  const courtsData = courts?.data ?? []

                  const sportName =
                    sportsData.find((sport) => sport.id === courtSport.sportId)
                      ?.name ?? ''

                  const courtNumber =
                    courtsData.find((court) => court.id === courtSport.courtId)
                      ?.courtNumber ?? ''

                  return (
                    <Select.Option value={courtSport.id} key={courtSport.id}>
                      Court #{courtNumber} - {sportName}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Date" name={`date[${index}]`}>
              <DatePicker.RangePicker
                showTime
                format="YYYY-MM-DD HH:mm"
                // @ts-ignore -- minuteStep is not in the type
                minuteStep={15}
                disabledDate={(current) => {
                  return current && current <= dayjs().startOf('day')
                }}
              />
            </Form.Item>
            <hr />
          </React.Fragment>
        ))}
        <Button
          type="primary"
          size="small"
          onClick={() => setSlotCount(slotCount + 1)}
        >
          +Add new slot
        </Button>
      </Form>
    </Card>
  )
}
