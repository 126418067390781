export const getKeysOf = <K extends string, V>(obj: { [key in K]: V }): K[] =>
  Object.keys(obj).filter((key): key is K => Number.isNaN(Number(key)))

export const getValuesOf = <K extends string, V>(obj: { [key in K]: V }): V[] =>
  getKeysOf(obj).map((key: K) => obj[key])

export enum UserRole {
  admin = 'admin',
  user = 'user',
}

export enum ClassSkillLevel {
  beginners = 'beginners',
  intermediate = 'intermediate',
  advanced = 'advanced',
  firstTimers = 'first-timers',
  all = 'all',
}

export enum UserSkillLevel {
  beginner = 'beginner',
  intermediate = 'intermediate',
  advanced = 'advanced',
  firstTimer = 'first-timer',
}

export enum ReservationType {
  /** previously known as private reservation */
  court = 'court-reservation',
  programmedClassInstructor = 'programmed-class-instructor',
  programmedClassOpenPlay = 'programmed-open-play',
  privateLesson = 'private-lesson',
}

export enum ClassType {
  openPlay = 'open-play',
  programmed = 'programmed',
  privateLesson = 'private-lesson',
}

export enum GroupType {
  residency = 'residency',
  specialGroup = 'special-group',
  forPurchase = 'for-purchase',
}

export enum LocationImages {
  detail = 'detail',
  thumbnail = 'thumbnail',
  pastReservation = 'pastReservation',
  mainGallery = 'mainGallery',
  smallGallery1 = 'smallGallery1',
  smallGallery2 = 'smallGallery2',
}

export enum ClassImages {
  class152x240 = 'class152x240',
  class240x140 = 'class240x140',
  class152x89 = 'class152x89',
}

export enum InstructorImages {
  instructorAvatar80x80 = 'instructorAvatar80x80',
  instructor240x140 = 'instructor240x140',
  instructor152x89 = 'instructor152x89',
  instructor152x240 = 'instructor152x240',
}

export enum TimeStatus {
  PAST = 'past',
  ONGOING = 'ongoing',
  FUTURE = 'future',
  CANCELLED = 'cancelled',
  UNKNOWN = 'unknown', // This is a fallback so we can easily check if the UTC offset is set everywhere
}

export enum CourtSlotType {
  PRIVATE = 'PRIVATE',
  INSTRUCTOR = 'INSTRUCTOR',
}
