import { useMany, useRouterContext, useUpdate } from '@refinedev/core'
import { Button, Card, Popconfirm, Typography } from 'antd'
import dayjs from 'dayjs'
import { ICourtResponse, ILocation, IReservation } from 'interfaces'
import { FC } from 'react'

export const ShowCourse: FC<{
  reservations?: IReservation[]
}> = ({ reservations }) => {
  if (!reservations) {
    return null
  }

  const { Link } = useRouterContext()
  const { mutate: mutateDelete } = useUpdate()

  const reservationsStartTimes = reservations.map((reservation) => {
    if (reservation.reservationTimestampRange) {
      return dayjs(reservation.reservationTimestampRange[0]).format(
        'MMMM D, YYYY h:mm A'
      )
    }
  })

  const { courts, locations } = reservations.reduce(
    (acc, { courtId, locationId }) => {
      acc.courts.add(courtId)
      acc.locations.add(locationId)
      return acc
    },
    { courts: new Set<string>(), locations: new Set<string>() }
  )

  const { data: courtsData } = useMany<ICourtResponse>({
    ids: [...courts],
    resource: 'courts',
  })
  const { data: locationsData } = useMany<ILocation>({
    ids: [...locations],
    resource: 'locations',
  })
  // sort reservationsStartTimes from the earliest to the latest
  const sortedReservationsStartTimes = reservationsStartTimes.sort((a, b) =>
    dayjs(a).isBefore(b) ? -1 : 1
  )

  const courseStartDate = sortedReservationsStartTimes[0]
  const courseEndDate =
    sortedReservationsStartTimes[sortedReservationsStartTimes.length - 1]

  const cancelCourse = () => {
    mutateDelete({
      resource: `reservations/${reservations[0]?.linkedReservationId}/course/cancel`,
      id: '',
      values: {},
      invalidates: ['all'],
    })
  }

  return (
    <>
      <Typography.Title level={4}>
        Reservations for the course:{' '}
      </Typography.Title>
      {reservations.map((reservation) => {
        const canceledTitle =
          reservation.canceledAt &&
          `Canceled at ${dayjs(reservation.canceledAt).format('LLL')}`
        const activeTitle =
          reservation.reservationTimestampRange &&
          `${dayjs(reservation.reservationTimestampRange[0]).format(
            'LLL'
          )} - ${dayjs(reservation.reservationTimestampRange[1]).format('LT')}`
        const title = reservation.canceledAt ? canceledTitle : activeTitle
        return (
          <Link
            key={reservation.id}
            to={`/reservations/show/${reservation?.id}`}
          >
            <Card
              key={reservation.id}
              style={{
                marginTop: 12,
              }}
              title={title}
            >
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {!reservation.canceledAt && (
                  <li>
                    <Typography.Text italic>Date:&nbsp;</Typography.Text>

                    <Typography.Text strong>
                      {dayjs(reservation.reservationTimestampRange?.[0]).format(
                        'LLL'
                      )}
                      &nbsp;-&nbsp;
                      {dayjs(reservation.reservationTimestampRange?.[1]).format(
                        'LLL'
                      )}
                    </Typography.Text>
                  </li>
                )}
                <li>
                  <Typography.Text italic>Location:&nbsp;</Typography.Text>
                  <Typography.Text strong>
                    {
                      locationsData?.data.find(
                        (c) => c.id === reservation.locationId
                      )?.name
                    }
                  </Typography.Text>
                </li>
                <li>
                  <Typography.Text italic>Court:&nbsp;</Typography.Text>
                  <Typography.Text strong>
                    #
                    {
                      courtsData?.data.find((c) => c.id === reservation.courtId)
                        ?.courtNumber
                    }
                  </Typography.Text>
                </li>
                <li>
                  <Typography.Text italic>ID:&nbsp;</Typography.Text>
                  <Typography.Text strong>{reservation.id}</Typography.Text>
                </li>
              </ul>
            </Card>
          </Link>
        )
      })}
      {courseStartDate && courseEndDate ? (
        <Popconfirm
          title="Cancel course"
          description="Are you sure to cancel this course and all corresponding user reservations?"
          onConfirm={cancelCourse}
          okText="Yes"
          cancelText="No"
        >
          <Button danger style={{ marginTop: 12 }}>
            Cancel course
          </Button>
        </Popconfirm>
      ) : (
        <Button danger disabled style={{ marginTop: 12 }}>
          Cancel course
        </Button>
      )}
    </>
  )
}
