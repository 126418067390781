import { Breadcrumb, Edit, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { Form, Input, InputNumber } from 'antd'

import type { IRuleResponse } from 'interfaces'

export const RulesEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, queryResult, saveButtonProps } = useForm<IRuleResponse>({
    action: 'edit',
    redirect: 'show',
    resource: 'rules',
  })

  return (
    <Edit
      canDelete
      breadcrumb={<Breadcrumb />}
      resource="rules"
      saveButtonProps={saveButtonProps}
    >
      <Form layout="vertical" {...formProps}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ message: 'Please enter a name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Price override" name={['rules', 'price']}>
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Reservation window override in days"
          name={['rules', 'reservationWindow']}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Edit>
  )
}
