import { PlusOutlined } from '@ant-design/icons'
import { Breadcrumb, Show, useForm } from '@refinedev/antd'
import {
  IResourceComponentsProps,
  useCreate,
  useDelete,
  useList,
  useShow,
} from '@refinedev/core'
import {
  Button,
  Col,
  Form,
  List,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from 'antd'

import type { IRuleResponse, ISport } from 'interfaces'
import { useState } from 'react'

export const RulesShow: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const data = useShow<IRuleResponse>()
  const [isAddSportsModalOpen, setIsAddSportsModalOpen] = useState(false)
  const { mutate: mutateCreate } = useCreate()
  const { mutate: mutateDelete } = useDelete()
  const { form: ruleSportFormModal, formProps: ruleSportFormModalProps } =
    useForm<IRuleResponse>()

  // 💿 DATA
  const record = data?.queryResult?.data?.data

  // fetch all sports
  const { data: sports } = useList<ISport>({ resource: 'sports' })

  // 🚀 Functions

  const connectSportRule = () => {
    const sportId = ruleSportFormModal.getFieldValue('selectedSportId')
    const ruleId = record?.id
    mutateCreate({
      resource: `rules/${ruleId}/sports`,
      values: {
        sportId,
      },
      invalidates: ['all'],
    })

    setIsAddSportsModalOpen(false)
  }

  const disconnectSportRule = async (sportId: string) => {
    const ruleId = record?.id
    mutateDelete({
      resource: `rules/${ruleId}/sports/${sportId}`,
      id: '',
      invalidates: ['all'],
    })
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={24} xl={24} xs={24}>
          <Show
            breadcrumb={<Breadcrumb />}
            resource="rules"
          >
            <Typography.Title level={3}>{record?.name}</Typography.Title>
            <Typography.Paragraph italic>
              Reservation Type:
            </Typography.Paragraph>
            <Typography.Paragraph>
              {record?.reservationType}
            </Typography.Paragraph>
            <Typography.Title level={4}>Rules:</Typography.Title>
            <Typography.Paragraph italic>Price</Typography.Paragraph>
            <Typography.Paragraph>{record?.rules.price}</Typography.Paragraph>
            <Typography.Paragraph italic>
              Reservation Window:
            </Typography.Paragraph>
            <Typography.Paragraph>
              {record?.rules.reservationWindow} days
            </Typography.Paragraph>
          </Show>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col lg={24} xl={24} xs={24}>
          <Typography.Title
            style={{
              marginTop: '20px',
            }}
            level={4}
          >
            Sports connected with this rule:
          </Typography.Title>
          <Button
            type="primary"
            style={{
              marginRight: '20px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
            onClick={() => {
              setIsAddSportsModalOpen(true)
            }}
          >
            <PlusOutlined />
            Connect Sport with this Rule{' '}
          </Button>
          <List>
            <Table dataSource={record?.sports} rowKey="id">
              <Table.Column title="Name" dataIndex="name" key="name" />
              <Table.Column
                dataIndex="description"
                key="description"
                title="Description"
              />
              <Table.Column
                dataIndex="id"
                key="disconnect"
                render={(_, record: ISport) => {
                  return (
                    <Button
                      danger
                      onClick={() => {
                        disconnectSportRule(record.id)
                      }}
                      size="small"
                    >
                      Disconnect
                    </Button>
                  )
                }}
                title="Disconnect"
              />
            </Table>
          </List>
        </Col>
      </Row>
      <Modal
        width={800}
        title="Connect Sport with this Rule"
        open={isAddSportsModalOpen}
        onOk={connectSportRule}
        onCancel={() => {
          setIsAddSportsModalOpen(false)
        }}
      >
        <Typography.Paragraph>Select a sport</Typography.Paragraph>
        <Form layout="vertical" {...ruleSportFormModalProps}>
          <Form.Item required label="Sport" name="selectedSportId">
            <Select
              placeholder="Select a sport"
              title="Select a group"
              style={{ width: 400 }}
            >
              {sports?.data.map((sport) => (
                <Select.Option key={sport.id} value={sport.id}>
                  {sport.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
