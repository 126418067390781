import { Breadcrumb, Show } from '@refinedev/antd'
import { IResourceComponentsProps, useShow, useUpdate } from '@refinedev/core'
import { Button, Col, Popconfirm, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import { IReservationUser } from 'interfaces'

export const UserReservationShow: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const data = useShow<IReservationUser>()
  const { mutate: mutateUpdate } = useUpdate()

  // 💿 DATA
  const record = data?.queryResult?.data?.data

  // 🚀 Functions

  const handleCancel = () => {
    mutateUpdate({
      resource: 'user-reservations',
      id: record?.id || '',
      values: {
        canceledAt: new Date().toISOString(),
      },
    })
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={24} xl={24} xs={24}>
          <Show
            breadcrumb={<Breadcrumb />}
            resource="reservations"
          >
            <Typography.Title level={3}>
              {record?.user?.firstName} {record?.user?.lastName}
            </Typography.Title>
            <Typography.Paragraph>
              <Typography.Text italic>ID:</Typography.Text> {record?.id}
            </Typography.Paragraph>

            <Typography.Paragraph>
              <Typography.Text italic>Created at:</Typography.Text>{' '}
              {dayjs(record?.createdAt).format('dddd, MMMM D, YYYY h:mm A')}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text italic>Updated at:</Typography.Text>{' '}
              {dayjs(record?.updatedAt).format('dddd, MMMM D, YYYY h:mm A')}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text italic>Canceled at:</Typography.Text>{' '}
              {record?.canceledAt &&
                dayjs(record?.canceledAt).format('dddd, MMMM D, YYYY h:mm A')}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text italic>Guests:</Typography.Text>{' '}
              {record?.numberOfGuests}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text italic>Paid:</Typography.Text>{' '}
              {record?.paid ? '✅' : '❌'}
            </Typography.Paragraph>
            {!record?.canceledAt && (
              <Popconfirm
                title="Cancel user's class reservation"
                description="Are you sure to cancel this reservation?"
                onConfirm={handleCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>⚠️ Cancel reservation</Button>
              </Popconfirm>
            )}
          </Show>
        </Col>
      </Row>
    </>
  )
}
