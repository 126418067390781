import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { List, useTable } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useDelete, useNavigation } from '@refinedev/core'
import {
  Avatar,
  Dropdown,
  Menu,
  Modal,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd'

import { useState } from 'react'
import type { ILocation } from '../../interfaces'

export const LocationList: React.FC<IResourceComponentsProps> = () => {
  const { edit, show } = useNavigation()
  const [isDeleteLocationModalOpen, setIsDeleteLocationModalOpen] =
    useState(false)
  const [locationToDeleteId, setLocationToDeleteId] = useState<string | null>(
    null
  )

  const { tableProps } = useTable<ILocation>({
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  })

  const { mutate: mutateDelete } = useDelete()

  const deleteLocation = () => {
    if (typeof locationToDeleteId === 'string') {
      mutateDelete(
        {
          id: locationToDeleteId,
          mutationMode: 'undoable',
          resource: 'locations',
        },
        {
          onSuccess: () => {
            setIsDeleteLocationModalOpen(false), setLocationToDeleteId(null)
          },
          onError: () => {},
        }
      )
    }
  }

  const handleCancelDeleteLocationModal = () => {
    setLocationToDeleteId(null)
    setIsDeleteLocationModalOpen(false)
  }

  const moreMenu = (id: string) => (
    <Menu
      mode="vertical"
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      <Menu.Item
        icon={
          <EyeOutlined
            style={{
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        key="detail"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          show('locations', id)
        }}
      >
        Detail
      </Menu.Item>
      <Menu.Item
        icon={
          <EditOutlined
            style={{
              color: '#52c41a',
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        key="edit"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          edit('locations', id)
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        icon={
          <CloseCircleOutlined
            style={{
              color: '#EE2A1E',
              fontSize: 17,
            }}
          />
        }
        key="delete"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          setLocationToDeleteId(id)
          setIsDeleteLocationModalOpen(true)
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            key="name"
            render={(record: ILocation) => (
              <Space>
                <Avatar size={74} src={record.images.detail} />
                <Typography.Text style={{ wordBreak: 'inherit' }}>
                  {record.name}
                </Typography.Text>
              </Space>
            )}
            title="Name"
          />
          <Table.Column dataIndex="description" title="Description" />
          <Table.Column
            dataIndex="gettingThereInfo"
            title="Getting there info"
          />
          <Table.Column dataIndex="accessInfo" title="Access info" />
          <Table.Column
            dataIndex="defaultReservationWindow"
            title={
              <Tooltip title="Default Reservation Window in days - Is a number of days from today within which a general user can make a booking. Can be overridden by user's 'groups' tags.">
                Def. Res. Win. ℹ️
              </Tooltip>
            }
          />
          <Table.Column
            dataIndex="reservationReleaseTimeLocal"
            title={
              <Tooltip title="Reservation Release Time - The time at which site reservations (e.g. court) are made avaiable for a given date. Reservations are made available on a rolling basis, subject to the reservation window, if release time is left empty">
                Res. Rel. Time ℹ️
              </Tooltip>
            }
          />
          <Table.Column
            dataIndex="utcOffsetMinutes"
            title={
              <Tooltip title="UTC Time Offset in minutes - Difference between Location's local time and UTC time (e.g. -420 minutes for San Francisco)">
                UTC Offset ℹ️
              </Tooltip>
            }
          />
          <Table.Column<ILocation>
            dataIndex="actions"
            fixed="right"
            key="actions"
            render={(_, record: ILocation) => (
              <Dropdown overlay={moreMenu(record.id)} trigger={['click']}>
                <MoreOutlined
                  style={{
                    fontSize: 24,
                  }}
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            )}
            title="Actions"
          />
        </Table>
      </List>
      <Modal
        title="Are you sure ?"
        open={isDeleteLocationModalOpen}
        onOk={deleteLocation}
        okButtonProps={{ danger: true, icon: <DeleteOutlined /> }}
        onCancel={handleCancelDeleteLocationModal}
      >
        <Typography.Paragraph type="danger">
          Deleting this Location will permanently delete all its related assets
          like Courts, Reservations, Classes and its images.
        </Typography.Paragraph>
      </Modal>
    </>
  )
}
