import { Breadcrumb, Edit, useForm } from '@refinedev/antd'
import { type IResourceComponentsProps } from '@refinedev/core'
import { Form, Input, InputNumber } from 'antd'
import type { ISportRating } from 'interfaces'

export const SportRatingEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ISportRating>({
    action: 'edit',
    redirect: 'show',
    resource: 'sport-ratings',
  })

  return (
    <Edit
      canDelete
      breadcrumb={<Breadcrumb />}
      resource="sport-ratings"
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical" style={{ marginTop: 30 }}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ message: "Please enter sport's rating name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ message: "Please enter sport's rating description" }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Enter rating" name="rating">
          <InputNumber />
        </Form.Item>
      </Form>
    </Edit>
  )
}
