import { ICourt } from 'interfaces'

export const CommaSeparatedCourts = (courts: ICourt[]) => {
  courts.sort((a, b) => {
    if (a.courtNumber > b.courtNumber) return 1
    else if (a.courtNumber < b.courtNumber) return -1
    else return 0
  })

  return courts?.reduce((m: string, c: ICourt, i: number) => {
    m = i > 0 ? m + ', ' + c.courtNumber : (c.courtNumber as unknown as string)
    return m
  }, '')
}
