import type { FC } from 'react'

export const ActionsWrapper: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: 8,
        justifyContent: 'flex-end',
        marginRight: 24,
      }}
    >
      {children}
    </div>
  )
}
