import {
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { useTable } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useDelete, useNavigation } from '@refinedev/core'
import { Dropdown, List, Menu, Table } from 'antd'
import dayjs from 'dayjs'

import type { IUser, IUsers } from '../../interfaces'

export const UsersList: React.FC<IResourceComponentsProps> = () => {
  const { edit, show } = useNavigation()
  const { tableProps } = useTable<IUsers>({
    sorters: {
      initial: [
        {
          field: 'email',
          order: 'asc',
        },
      ],
    },
    pagination: {
      pageSize: 10,
      mode: 'server',
    },
  })
  const { mutate: mutateDelete } = useDelete()

  const moreMenu = (id: string) => (
    <Menu
      mode="vertical"
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      {' '}
      <Menu.Item
        icon={
          <EyeOutlined
            style={{
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        key="detail"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          show('users', id)
        }}
      >
        Detail
      </Menu.Item>
      <Menu.Item
        icon={
          <EditOutlined
            style={{
              color: '#52c41a',
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        key="accept"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          edit('users', id)
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        icon={
          <CloseCircleOutlined
            style={{
              color: '#EE2A1E',
              fontSize: 17,
            }}
          />
        }
        key="reject"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          mutateDelete({
            id,
            mutationMode: 'undoable',
            resource: 'users',
          })
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <List>
      <Table
        {...tableProps}
        pagination={{
          ...tableProps.pagination,
          pageSizeOptions: [10, 100, 1000],
          hideOnSinglePage: false,
          showSizeChanger: true,
        }}
        rowKey="id"
      >
        <Table.Column<IUser> dataIndex="firstName" title="First Name" sorter />
        <Table.Column<IUser> dataIndex="lastName" title="Last Name" sorter />
        <Table.Column<IUser> dataIndex="email" title="Email" sorter />
        <Table.Column<IUser> dataIndex="phone" title="Phone" sorter />
        <Table.Column<IUser> dataIndex="role" title="Role" width={90} />
        <Table.Column<IUser>
          dataIndex="createdAt"
          title="Created At"
          render={(value: Date) => dayjs(value).format('lll')}
          width={180}
          sorter
        />
        <Table.Column<IUser>
          dataIndex="updatedAt"
          title="Updated At"
          render={(value: Date) => dayjs(value).format('lll')}
          width={180}
          sorter
        />
        <Table.Column<IUser>
          dataIndex="actions"
          fixed="right"
          key="actions"
          render={(_, record: IUser) => (
            <Dropdown overlay={moreMenu(record.id)} trigger={['click']}>
              <MoreOutlined
                style={{
                  fontSize: 24,
                }}
                onClick={(e) => e.stopPropagation()}
              />
            </Dropdown>
          )}
          width={90}
          align="right"
          title="Actions"
        />
      </Table>
    </List>
  )
}
