import { useForm } from '@refinedev/antd'
import { useCreate, useInvalidate, useList } from '@refinedev/core'
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
} from 'antd'
import { ActionsWrapper } from 'components/locations/courts/actionsWrapper'
import dayjs from 'dayjs'
import { IClass, ICourtResponse, ILocation, ISport } from 'interfaces'
import { FC, useMemo, useState } from 'react'

export const CreateReservation: FC<{
  classData: IClass
}> = ({ classData }) => {
  const invalidate = useInvalidate()
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(
    classData.locationId
  )
  const { formProps: reservationFormProps, form: reservationForm } = useForm()
  const { mutate: createReservation } = useCreate()

  // fetch locations to select from
  const { data: locations } = useList<ILocation>({
    resource: 'locations',
  })

  // fetch courts to select from
  const { data: courts } = useList<ICourtResponse>({
    queryOptions: {
      enabled: !!selectedLocationId,
      queryKey: ['list', { locationId: selectedLocationId }],
    },

    resource: `courts?locationId=${selectedLocationId}`,
  })

  const { data: sports } = useList<ISport>({
    resource: 'sports',
  })

  // fetch all groups for select options
  const { data: groups } = useList({
    resource: 'groups',
  })

  const courtSports = useMemo(() => {
    const courtsData = courts?.data ?? []

    const filteredBySport = courtsData
      .flatMap((court) => court.sports)
      .filter((courtSport) => courtSport.sportId === classData.sportId)

    return filteredBySport
  }, [courts])

  const getCourts = (locationId: string) => {
    if (locationId) {
      setSelectedLocationId(locationId)

      invalidate({
        invalidates: ['list'],
        resource: 'courts',
      })
    }
  }

  const handleReservation = () => {
    const values: any = reservationFormProps.form?.getFieldsValue()

    type Request = {
      courtSportIds: string[]
      classId: string
      capacity: number
      from: {
        date: string
        time: string
      }
      to: {
        date: string
        time: string
      }
      reservationType: string
      groupsOnly?: string[] | null
    }

    const startDate = dayjs(values.date[0].$d as Date).format('YYYY-MM-DD')
    const startTime = dayjs(values.date[0].$d as Date).format('HH:mm:00')
    const endDate = dayjs(values.date[1].$d as Date).format('YYYY-MM-DD')
    const endTime = dayjs(values.date[1].$d as Date).format('HH:mm:00')

    const requestValues: Request = {
      courtSportIds: values.courtSportIds,
      classId: values.classId,
      capacity: values.capacity,
      reservationType: values.reservationType,
      from: {
        date: startDate,
        time: startTime,
      },
      to: {
        date: endDate,
        time: endTime,
      },
      groupsOnly: values.groupsOnly,
    }

    createReservation(
      {
        resource: 'reservations',
        values: requestValues,
      },
      {
        onSuccess: () => {
          reservationForm.resetFields()
        },
      }
    )
  }

  return (
    <Card
      title="Create Reservation"
      actions={[
        <ActionsWrapper>
          <Button type="primary" onClick={handleReservation}>
            Create
          </Button>
        </ActionsWrapper>,
      ]}
    >
      <Form layout="vertical" {...reservationFormProps}>
        <Form.Item
          label="Location"
          name="locationId"
          rules={[{ message: 'Please enter a location' }, { required: true }]}
        >
          <Select allowClear onChange={getCourts}>
            {locations?.data.map((location) => (
              <Select.Option value={location.id} key={location.id}>
                {location.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Court & Sport" name="courtSportIds">
          <Select mode="multiple" allowClear>
            {courtSports?.map((courtSport) => {
              const sportsData = sports?.data ?? []
              const courtsData = courts?.data ?? []

              const sportName =
                sportsData.find((sport) => sport.id === courtSport.sportId)
                  ?.name ?? ''

              const courtNumber = courtsData.find(
                (court) => court.id === courtSport.courtId
              )?.courtNumber

              return (
                <Select.Option value={courtSport.id} key={courtSport.id}>
                  Court #{courtNumber} - {sportName}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Class"
          name="classId"
          initialValue={classData?.id}
          hidden
        >
          <Input disabled />
        </Form.Item>
        <Form.Item label="Date" name="date">
          <DatePicker.RangePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            // @ts-ignore -- minuteStep is not in the type
            minuteStep={15}
            disabledDate={(current) => {
              return current && current <= dayjs().startOf('day')
            }}
          />
        </Form.Item>
        <Form.Item label="Capacity" name="capacity">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Restrict only to group members" name="groupsOnly">
          <Select allowClear mode="multiple" style={{ maxWidth: 300 }}>
            {groups?.data.map((group) => (
              <Select.Option value={group.id} key={group.id}>
                {group.name}
              </Select.Option>
            ))}
            ``
          </Select>
        </Form.Item>
      </Form>
    </Card>
  )
}
