import { Breadcrumb, Create, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useInvalidate, useList } from '@refinedev/core'
import { Form, Input, InputNumber, Select, Switch } from 'antd'
import { useState } from 'react'

import type { IClass, ILocation, ISport, Instructor } from 'interfaces'

export const ClassesCreate: React.FC<IResourceComponentsProps> = () => {
  //🪝 HOOKS
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null)
  const [allowGuests, setAllowGuests] = useState<boolean>(false)
  const [equipmentIncluded, setEquipmentIncluded] = useState<boolean>(false)
  const invalidate = useInvalidate()
  const { formProps, saveButtonProps } = useForm<IClass>({
    redirect: 'edit',
  })

  // 💿 DATA
  // fetch instructors to select from
  const { data: instructors } = useList<Instructor>({
    resource: 'instructors',
  })

  // fetch locations to select from
  const { data: locations } = useList<ILocation>({
    resource: 'locations',
  })

  const { data: sports } = useList<ISport>({
    resource: 'sports',
  })

  // 🧰 FUNCTIONS
  const getCourts = (locationId: string) => {
    if (locationId) {
      setSelectedLocation(locationId)

      invalidate({
        invalidates: ['list'],
        resource: 'courts',
      })
    }
  }

  const instructorsData = instructors?.data ?? []
  instructorsData.sort((a, b) => {
    const aLastName = (a.user?.lastName ?? '').toLowerCase()
    const bLastName = (b.user?.lastName ?? '').toLowerCase()

    return aLastName > bLastName ? 1 : -1
  })

  return (
    <Create
      breadcrumb={<Breadcrumb />}
      resource="classes"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        initialValues={{
          isActive: true,
        }}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ message: 'Please enter a name' }, { required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            { message: 'Please enter a description' },
            { required: true },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Instructor"
          name="instructorId"
          rules={[{ message: 'Please enter an instructor' }]}
        >
          <Select allowClear>
            {instructorsData.map((instructor) => (
              <Select.Option key={instructor.id} value={instructor.id}>
                <>
                  <strong>
                    {instructor.user?.firstName} {instructor.user?.lastName}
                  </strong>{' '}
                  {instructor.user?.email}
                </>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Location"
          name="locationId"
          rules={[{ message: 'Please enter a location' }, { required: true }]}
        >
          <Select allowClear>
            {locations?.data
              .sort((l1, l2) =>
                l1.name.toLowerCase().localeCompare(l2.name.toLocaleLowerCase())
              )
              .map((location) => (
                <Select.Option value={location.id} key={location.id}>
                  {location.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Sport"
          name="sportId"
          rules={[{ message: 'Please enter a sport' }, { required: true }]}
        >
          <Select>
            {sports?.data.map((sport) => (
              <Select.Option value={sport.id}>{sport.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Price (in cents)"
          name="price"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          initialValue="USD"
          label="Currency"
          name="currency"
          rules={[{ message: 'Please enter a currency' }]}
        >
          <Select style={{ maxWidth: 200 }}>
            <Select.Option label="USD" value="USD">
              USD
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Recommended level"
          name="recommendedLevel"
          rules={[
            { message: 'Please enter recommended level' },
            { required: true },
          ]}
        >
          <Select>
            <Select.Option value="beginners">beginner</Select.Option>
            <Select.Option value="intermediate">intermediate</Select.Option>
            <Select.Option value="advanced">advanced</Select.Option>
            <Select.Option value="first-timers">first-timers</Select.Option>
            <Select.Option value="all">all</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Enforce recommended level?"
          name="enforceRecommendedLevel"
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value={true}>Yes</Select.Option>
            <Select.Option value={false}>No</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Equipment included?" name="equipmentIncluded">
          <Select style={{ maxWidth: 200 }} onChange={setEquipmentIncluded}>
            <Select.Option value>Yes</Select.Option>
            <Select.Option value={false}>No</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Allow guests?"
          name="allowGuests"
          rules={[{ required: true }]}
        >
          <Select style={{ maxWidth: 200 }} onChange={setAllowGuests}>
            <Select.Option value>Yes</Select.Option>
            <Select.Option value={false}>No</Select.Option>
          </Select>
        </Form.Item>
        {allowGuests && (
          <>
            <Form.Item label="Guest price" name="guestPrice">
              <InputNumber />
            </Form.Item>
            <Form.Item
              initialValue="USD"
              label="Guest currency"
              name="guestCurrency"
            >
              <Select allowClear style={{ maxWidth: 200 }}>
                <Select.Option value="USD">USD</Select.Option>
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item
          label="Type"
          name="type"
          rules={[{ message: 'Please enter class type' }, { required: true }]}
        >
          <Select>
            <Select.Option value="open-play">open-play</Select.Option>
            <Select.Option value="programmed">programmed</Select.Option>
            <Select.Option value="private-lesson">private-lesson</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Default reservation window"
          name="defaultReservationWindow"
          rules={[
            { required: true, message: 'Please enter reservation window!' },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Do not market" name="doNotMarket">
          <Switch />
        </Form.Item>
      </Form>
    </Create>
  )
}
