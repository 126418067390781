import React, { useCallback } from 'react'
import { Breadcrumb, Create, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { Form, Input, Select } from 'antd'
import type { IOrganization } from 'interfaces'
import { useList } from '@refinedev/core'

const slugify = (str: string) => {
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

export const OrganizationsCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IOrganization>()
  const { data: locations } = useList({
    resource: 'locations',
  })

  const handleNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value
      const newSlug = slugify(newName)

      formProps.form?.setFieldsValue({ slug: newSlug })
    },
    [formProps.form]
  )

  return (
    <Create
      breadcrumb={<Breadcrumb />}
      resource="organizations"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        initialValues={{
          isActive: true,
        }}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input onChange={handleNameChange} />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true }]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Locations" name="locations">
          <Select mode="multiple">
            {locations?.data.map((item) => (
              <Select.Option value={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Create>
  )
}
