import React from 'react'
import { Breadcrumb, Create, useForm } from '@refinedev/antd'
import { IResourceComponentsProps, useList } from '@refinedev/core'
import { Form, Input, Select } from 'antd'
import { IGlobalActivity, IGlobalCategory } from 'interfaces'

export const GlobalActivitiesCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IGlobalActivity>()
  const { data: globalCategories } = useList<IGlobalCategory>({
    resource: 'global-categories',
  })

  return (
    <Create
      breadcrumb={<Breadcrumb />}
      resource="global-activities"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      <Form.Item
          label="Global Category"
          name="globalCategoryId"
          rules={[{ message: 'Please select category', required: true }]}
      >
        <Select>
          {globalCategories?.data.map((item) => (
              <Select.Option value={item.id}>
                {item.name}
              </Select.Option>
          ))}
        </Select>
      </Form.Item>
      </Form>
    </Create>
  )
}
