import { Edit, useForm } from '@refinedev/antd'
import { IResourceComponentsProps, useList } from '@refinedev/core'
import { Form, Input, Select } from 'antd'

import { ICategory, IGlobalCategory, IOrganization } from 'interfaces'
import React from 'react'

export const CategoriesEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ICategory>({
    action: 'edit',
    redirect: 'show',
    resource: 'categories',
  })
    const { data: organizations } = useList<IOrganization>({
        resource: 'organizations',
    })
    const { data: globalCategories } = useList<IGlobalCategory>({
        resource: 'global-categories',
    })

  return (
    <Edit canDelete resource="categories" saveButtonProps={saveButtonProps}>
      <Form
        layout="vertical"
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
            globalCategoryId: queryResult?.data?.data.globalCategoryId,
            organizationId: queryResult?.data?.data.organizationId,
        }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
          <Form.Item
              label="Organization"
              name="organizationId"
              rules={[{ message: 'Please select organization', required: true }]}
          >
              <Select>
                  <Select.Option value={undefined}>
                      No organization
                  </Select.Option>
                  {organizations?.data.map((organization) => (
                      <Select.Option value={organization.id}>
                          {organization.name}
                      </Select.Option>
                  ))}
              </Select>
          </Form.Item>
          <Form.Item
              label="Global Category"
              name="globalCategoryId"
              rules={[{ message: 'Please select category', required: true }]}
          >
              <Select>
                  <Select.Option>
                      No category
                  </Select.Option>
                  {globalCategories?.data.map((item) => (
                      <Select.Option value={item.id}>
                          {item.name}
                      </Select.Option>
                  ))}
              </Select>
          </Form.Item>
      </Form>
    </Edit>
  )
}
