import React, { useCallback } from 'react'
import { Breadcrumb, Create, useForm } from '@refinedev/antd'
import { IResourceComponentsProps, useList } from '@refinedev/core'
import { Form, Input, Select } from 'antd'
import { IActivity, ICategory, IGlobalActivity, IOrganization } from 'interfaces'

export const ActivitiesCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IActivity>()
  const { data: organizations } = useList<IOrganization>({
    resource: 'organizations',
  })
  const { data: categories } = useList<ICategory>({
    resource: 'categories',
  })
  const { data: globalActivities } = useList<IGlobalActivity>({
    resource: 'global-activities',
  })

  const [selectedOrgId, setSelectedOrg] = React.useState<string | undefined>()
  const handleOrgChange = useCallback(
      (value: string) => {
        setSelectedOrg(value)
        formProps.form?.setFieldsValue({ categoryId: '' })
      },
      [formProps.form]
  )

  return (
    <Create
      breadcrumb={<Breadcrumb />}
      resource="activities"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
            label="Organization"
            name="organizationId"
            rules={[{ message: 'Please select organization', required: true }]}
        >
          <Select onChange={handleOrgChange}>
            {organizations?.data.map((item) => (
                <Select.Option value={item.id}>
                  {item.name}
                </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
            label="Category Id"
            name="categoryId"
            rules={[{ message: 'Please select category', required: true }]}
        >
          <Select>
            {categories
                ?.data
                ?.filter((item) => {
                  const organizationId = selectedOrgId
                  return organizationId
                      ? item.organizationId === organizationId
                      : true
                })
                .map((item) => (
                <Select.Option value={item.id}>
                  {item.name}
                </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
            label="Global Activity"
            name="globalActivityId"
            rules={[{ message: 'Please select activity', required: true }]}
        >
          <Select>
            {globalActivities?.data.map((item) => (
                <Select.Option value={item.id}>
                  {item.name}
                </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Create>
  )
}
