import { Form, type FormInstance, Select } from 'antd'
import { IANAZone } from 'luxon'

type Props = {
  form: FormInstance
}

const TimezoneInput: React.FC<Props> = ({ form }) => {
  const updateTimezone = (value: string) => {
    const zone = IANAZone.create(value)
    form.setFieldValue('utcOffsetMinutes', zone.offset(Date.now()))
  }

  return (
    <Form.Item
      label="Timezone"
      name="timezone"
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select onChange={updateTimezone}>
        <Select.Option value="America/Los_Angeles">
          Pacific (PST/PDT)
        </Select.Option>
        <Select.Option value="America/Denver">Mountain (MST/MDT)</Select.Option>
        <Select.Option value="America/Chicago">Central (CST/CDT)</Select.Option>
        <Select.Option value="America/New_York">
          Eastern (EST/EDT)
        </Select.Option>
        <Select.Option value="America/Phoenix">
          Arizona (MST, no DST)
        </Select.Option>
        <Select.Option value="Pacific/Honolulu">
          Honolulu (HST, no DST)
        </Select.Option>
        <Select.Option value="America/Anchorage">
          Alaska (AKST/AKDT)
        </Select.Option>
      </Select>
    </Form.Item>
  )
}

export default TimezoneInput
