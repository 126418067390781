import ky from 'ky'

import { firebaseAuth } from 'auth/auth'
import { setAccessToken } from './authProvider'

export interface IError {
  message: string
  type: string
}

export class HttpError extends Error {
  constructor(public msg: string, public statusCode: number) {
    super(`${statusCode}: ${msg}`)
    Object.setPrototypeOf(this, HttpError.prototype)
  }
}

export enum HttpStatus {
  'OK' = 200,
  'CREATED' = 201,
  'ACCEPTED' = 202,
  'NO_CONTENT' = 204,
  'BAD_REQUEST' = 400,
  'UNAUTHORIZED' = 401,
  'FORBIDDEN' = 403,
  'NOT_FOUND' = 404,
  'METHOD_NOT_ALLOWED' = 405,
  'CONFLICT' = 409,
  'UNPROCESSABLE_ENTITY' = 422,
}

export const OK_STATUSES = [
  HttpStatus.OK,
  HttpStatus.CREATED,
  HttpStatus.ACCEPTED,
  HttpStatus.NO_CONTENT,
]

export const apiClient = ky.extend({
  hooks: {
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401) {
          const newToken = await firebaseAuth.currentUser?.getIdToken(true)
          setAccessToken(newToken)
        }
      },
      async (request, options, response) => {
        if (!OK_STATUSES.includes(response.status)) {
          const body = (await response.json()) as IError
          throw new HttpError(body.message, response.status)
        }
      },
    ],
    beforeRequest: [
      async (request) => {
        const accessToken = await firebaseAuth.currentUser?.getIdToken()
        setAccessToken(accessToken)
        request.headers.set('Authorization', `Bearer ${accessToken}` ?? '')
      },
    ],
  },
  retry: 0,
})
