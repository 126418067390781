import { Edit, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { Form, Input } from 'antd'

import type { IGlobalCategory } from 'interfaces'
import React from 'react'

export const GlobalCategoriesEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IGlobalCategory>({
    action: 'edit',
    redirect: 'show',
    resource: 'global-categories',
  })

  return (
    <Edit canDelete resource="global-categories" saveButtonProps={saveButtonProps}>
      <Form
        layout="vertical"
        {...formProps}
        initialValues={{
          ...formProps.initialValues,
        }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
