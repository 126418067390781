import { Breadcrumb, Create, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { Form, Input, InputNumber, Select } from 'antd'
import type { IRuleResponse } from 'interfaces'
import { ReservationType, getValuesOf } from 'utils/enums'

export const RulesCreate: React.FC<IResourceComponentsProps> = () => {
  //🪝 HOOKS
  const { formProps, saveButtonProps } = useForm<IRuleResponse>()

  const reservationTypes = getValuesOf(ReservationType)

  return (
    <Create
      breadcrumb={<Breadcrumb />}
      resource="rules"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        initialValues={{
          isActive: true,
        }}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ message: 'Please enter a rule name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Reservation Type"
          name="reservationType"
          rules={[{ message: 'Please enter reservation type' }]}
        >
          <Select>
            {reservationTypes.map((reservationType) => (
              <Select.Option value={reservationType} key={reservationType}>
                {reservationType}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Price override"
          name={['rules', 'price']}
          initialValue={null}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          initialValue={null}
          label="Reservation window override in days"
          name={['rules', 'reservationWindow']}
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Create>
  )
}
