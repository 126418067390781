import { Breadcrumb, Show } from '@refinedev/antd'
import { IResourceComponentsProps, useList, useShow } from '@refinedev/core'
import { Typography } from 'antd'

import type { ISport, ISportRating } from 'interfaces'

export const SportRatingShow: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const data = useShow<ISportRating>()

  // 💿 DATA
  const record = data?.queryResult?.data?.data

  // fetch all sports
  const { data: sports } = useList<ISport>({ resource: 'sports' })

  return (
    <>
      <Show
        breadcrumb={<Breadcrumb />}
        resource="sport-ratings"
        canEdit={true}
      >
        <Typography.Paragraph italic>Name:</Typography.Paragraph>
        <Typography.Paragraph>{record?.name}</Typography.Paragraph>
        <Typography.Paragraph italic>Description:</Typography.Paragraph>
        <Typography.Paragraph>{record?.description}</Typography.Paragraph>
        <Typography.Paragraph italic>Rating:</Typography.Paragraph>
        <Typography.Paragraph>{record?.rating}</Typography.Paragraph>
        <Typography.Paragraph italic>Sport:</Typography.Paragraph>
        <Typography.Paragraph>
          {sports?.data.find((sport) => sport.id === record?.sportId)?.name}
        </Typography.Paragraph>
      </Show>
    </>
  )
}
