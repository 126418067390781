import { List, ShowButton, useTable } from '@refinedev/antd'
import { IResourceComponentsProps, useMany } from '@refinedev/core'
import { Table } from 'antd'
import { IRuleResponse } from 'interfaces'

export const RulesList: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const { tableProps } = useTable<IRuleResponse>({
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  })

  // 💿 DATA
  const record = tableProps.dataSource

  const { data: groups } = useMany<IRuleResponse>({
    ids: record?.map((rule) => rule.id) ?? [],
    queryOptions: {
      enabled: !!record?.length,
    },
    resource: 'rules',
  })

  return (
    <List canCreate createButtonProps={{ size: 'small' }}>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="reservationType" title="Reservation Type" />
        <Table.Column
          dataIndex="id"
          key="show"
          render={(_, record: IRuleResponse) => {
            return (
              <ShowButton
                hideText
                id={record.id}
                recordItemId={record.id}
                resource="rules"
                size="small"
              />
            )
          }}
          title="Detail"
        />
      </Table>
    </List>
  )
}
