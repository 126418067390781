import type {
  CrudFilter,
  CrudFilters,
  DataProvider,
  LogicalFilter,
} from '@refinedev/core'
import type { KyInstance } from 'ky/distribution/types/ky'

import { apiClient as defaultApiClient } from './apiClient'

export interface IPaginatedOptions {
  orderColumn?: string
  orderDirection?: 'asc' | 'desc'
  page?: number
  pageSize?: number
}

export interface IPaginatedResponse<TData = unknown> {
  results: TData[]
  total: number
}

export interface ICursorPaginatedResponse<TData = unknown> {
  cursor: string
  results: TData[]
}

export const SKIP_FETCH = 'skipFetch'

export const capitalize = (str?: string): string =>
  str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : ''

const transformEq = (value: LogicalFilter): string => value.field

const transformGt = (value: LogicalFilter): string =>
  `from${capitalize(value.field)}`

const transformLt = (value: LogicalFilter): string =>
  `to${capitalize(value.field)}`

const keyFactory = (filter: CrudFilter): string => {
  switch (filter.operator) {
    case 'eq':
      return transformEq(filter)

    case 'gt':

    case 'gte':
      return transformGt(filter)

    case 'lt':

    case 'lte':
      return transformLt(filter)

    default:
      // eslint-disable-next-line
      console.error('Invalid filter operator', filter)

      return ''
  }
}

export const transformCrudFilters = (
  filters: CrudFilters
): Record<string, unknown> =>
  filters.reduce(
    (acc, filter) => ({ ...acc, [keyFactory(filter)]: filter.value }),
    {}
  )

export function createDataProvider(
  prefixUrl: string,
  apiClient: KyInstance = defaultApiClient.extend({ prefixUrl })
): DataProvider {
  async function create<TData>({
    resource,
    variables,
  }: Parameters<DataProvider['create']>['0']) {
    return await apiClient
      .post(`${resource}`, { json: variables })
      .json<TData>()
      .then((data) => ({ data }))
  }

  async function createMany<TData>({
    resource,
    variables,
  }: // @ts-expect-error
  Parameters<DataProvider['createMany']>['0']) {
    const updatedData = await Promise.all(
      variables.map(
        async (variable) =>
          (
            await create<TData>({ resource, variables: variable })
          ).data
      )
    )

    return { data: updatedData }
  }

  async function custom<TData>({
    headers,
    method,
    payload,
    url,
  }: Parameters<Required<DataProvider>['custom']>['0']) {
    return await apiClient(url, {
      headers,
      json: payload,
      method,
    })
      .json<TData>()
      .then((data) => ({ data }))
  }

  async function deleteOne<TData>({
    id,
    metaData,
    resource,
  }: Parameters<DataProvider['deleteOne']>['0']) {
    const { data } = !metaData?.[SKIP_FETCH]
      ? await getOne<TData>({ id, resource }).catch((e) => ({
          data: {} as TData,
          error: e,
        }))
      : { data: {} as TData }

    return await apiClient
      .delete(`${resource}/${id}`)
      .json<TData>()
      .then(() => ({ data }))
  }

  async function deleteMany<TData>({
    ids,
    resource,
    variables,
  }: // @ts-expect-error
  Parameters<DataProvider['deleteMany']>['0']) {
    const updatedData = await Promise.all(
      ids.map(
        async (id) => (await deleteOne<TData>({ id, resource, variables })).data
      )
    )

    return { data: updatedData }
  }

  async function getList<TData>({
    filters,
    pagination,
    resource,
    sorters,
  }: Parameters<DataProvider['getList']>['0']) {
    const options: IPaginatedOptions | Record<string, string> = {
      // TODO: We have not enabled filtering for any endpoints.
      ...transformCrudFilters(filters ?? []),
    }

    // TODO: We have not enabled pagination and sorting for all our admin endpoints,
    // meaning there will be a 422 error returned if the relevant query parameters are passed.
    //
    // As we enable pagination/sorting for those endpoints, add the resource name to the array below.
    const RESOURCES_WITH_PAGINATION_AND_SORTING_ENABLED = ['users']

    if (RESOURCES_WITH_PAGINATION_AND_SORTING_ENABLED.includes(resource)) {
      if (pagination) {
        // pagination.current is 1-indexed, not 0-indexed
        options.page =
          pagination.current !== undefined ? pagination.current - 1 : undefined
        options.pageSize = pagination.pageSize
      }

      // only use one sortable column, not implementing multi-column sorting
      if (sorters && sorters[0]) {
        options.orderColumn = sorters[0].field
        options.orderDirection = sorters[0].order
      }
    }

    const query = new URLSearchParams(
      Object.entries(options).reduce(
        (acc, [key, value]) =>
          value || value === 0 ? { ...acc, [key]: value } : acc,
        {}
      )
    )

    const url = [resource, query.toString()]
      .filter((val) => Boolean(val))
      .join('?')

    return await apiClient
      .get(url)
      .json<IPaginatedResponse<TData> | TData[]>()
      .then((result) => {
        if (Array.isArray(result)) {
          return { data: result, total: result.length }
        }

        const { results, total } = result

        if (results && Number.isInteger(total)) {
          return { data: results, total }
        }
        // eslint-disable-next-line
        console.error('Invalid response', result)

        return { data: [], total: 0 }
      })
  }

  async function getOne<TData>({
    id,
    resource,
  }: Parameters<DataProvider['getOne']>['0']) {
    return await apiClient
      .get(`${resource}/${id}`)
      .json<TData>()
      .then((data) => ({ data }))
  }

  async function getMany<TData>({
    ids,
    resource,
  }: // @ts-expect-error
  Parameters<DataProvider['getMany']>['0']) {
    const data = await Promise.all(
      ids.map(async (id) => (await getOne<TData>({ id, resource })).data)
    )

    return { data }
  }

  async function update<TData>({
    id,
    resource,
    variables,
    meta,
  }: Parameters<DataProvider['update']>['0']) {
    if (meta?.httpMethod === 'put') {
      return await apiClient
        .put(`${resource}/${id}`, { json: variables })
        .json<TData>()
        .then((data) => ({ data }))
    } else {
      return await apiClient
        .patch(`${resource}/${id}`, { json: variables })
        .json<TData>()
        .then((data) => ({ data }))
    }
  }

  async function updateMany<TData>({
    ids,
    resource,
    variables,
  }: // @ts-expect-error
  Parameters<DataProvider['updateMany']>['0']) {
    const updatedData = await Promise.all(
      ids.map(
        async (id) =>
          (
            await update<TData>({
              id,
              resource,
              variables:
                typeof variables === 'object' &&
                variables !== null &&
                id in variables
                  ? (variables as Record<string, unknown>)[id]
                  : variables,
            })
          ).data
      )
    )

    return { data: updatedData }
  }

  return {
    create,
    createMany,
    custom,
    deleteMany,
    deleteOne,
    getApiUrl: () => prefixUrl,
    getList,
    getMany,
    getOne,
    update,
    updateMany,
  }
}
