import { Wrapper } from '@googlemaps/react-wrapper'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'

interface MapProps extends Exclude<google.maps.MapOptions, 'center'> {
  center?: google.maps.LatLngLiteral
  formattedAddress?: string
  getMapData: ({
    formattedAddress,
    lat,
    lng,
    placeId,
    utcOffsetMinutes,
  }: {
    formattedAddress?: string
    lat: number
    lng: number
    placeId?: string
    utcOffsetMinutes?: number
  }) => void
}

let map: google.maps.Map | null = null

const MapContent: React.FC<MapProps> = ({
  formattedAddress,
  getMapData,
  ...mapProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const mapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (mapProps.center) {
      map?.setCenter(mapProps.center)

      new google.maps.Marker({
        map,
        position: {
          lat: mapProps.center.lat,
          lng: mapProps.center.lng,
        },
      })
    }
  }, [mapProps.center])

  useEffect(() => {
    inputRef.current?.setAttribute('value', formattedAddress || '')
  }, [inputRef, formattedAddress])

  useEffect(() => {
    map = new google.maps.Map(mapRef.current!, {
      ...mapProps,
    })

    const searchBox = new google.maps.places.SearchBox(inputRef.current!)

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputRef.current!)

    const onPlacesChanged = () => {
      let markers: google.maps.Marker[] = []
      const places = searchBox.getPlaces()

      if (places?.length === 0) {
        return
      }

      markers.forEach((marker) => {
        marker.setMap(null)
      })

      markers = []

      const bounds = new google.maps.LatLngBounds()

      places?.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log('Returned place contains no geometry')

          return
        }

        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        const placeId = place.place_id
        const formattedAddress = place.formatted_address
        const utcOffsetMinutes = place.utc_offset_minutes

        getMapData({ formattedAddress, lat, lng, placeId, utcOffsetMinutes })

        markers.push(
          new google.maps.Marker({
            map,
            position: place.geometry.location,
            title: place.name,
          })
        )

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }
      })

      map?.fitBounds(bounds)
    }

    searchBox.addListener('places_changed', onPlacesChanged)
  }, [])

  return (
    <>
      <input
        defaultValue={formattedAddress}
        placeholder="Search"
        ref={inputRef}
        style={{
          border: '1px solid #d9d9d9',
          borderRadius: '6px',
          fontSize: '14px',
          marginTop: '10px',
          minHeight: '40px',
          padding: '4px 11px',
          width: 'calc(100% - 250px)',
        }}
        type="text"
      />
      <div ref={mapRef} style={{ height: '500px', width: '100%' }} />
    </>
  )
}

export const Map: FC<MapProps> = (props) => {
  return (
    <Wrapper apiKey={process.env.REACT_APP_MAP_API_KEY} libraries={['places']}>
      <MapContent {...props} />
    </Wrapper>
  )
}
