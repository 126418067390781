import { Breadcrumb, Show } from '@refinedev/antd'
import {
  IResourceComponentsProps,
  useList,
  useOne,
  useRouterContext,
  useShow,
  useUpdate,
} from '@refinedev/core'
import { Button, Col, Divider, Popconfirm, Row, Typography } from 'antd'
import dayjs from 'dayjs'
import {
  IClass,
  ICourtResponse,
  IGroup,
  ILocation,
  IReservation,
  IUser,
  Instructor,
} from 'interfaces'
import { ReservationType } from 'utils/enums'
import { CommaSeparatedCourts } from 'utils/helpers'

export const ReservationShow: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const data = useShow<IReservation>()
  const { Link } = useRouterContext()
  const { mutate: mutateUpdate } = useUpdate()

  // 💿 DATA
  const record = data?.queryResult?.data?.data

  // fetch other relevant data here:

  const { data: allGroups } = useList<IGroup>({
    resource: 'groups',
  })

  let reservationGroups: IGroup[] | null

  if (record?.groupsOnly && allGroups?.data) {
    reservationGroups = allGroups?.data.filter((group) =>
      record?.groupsOnly?.includes(group.id)
    )
  } else {
    reservationGroups = null
  }

  const { data: location } = useOne<ILocation>({
    id: record?.locationId || '',
    queryOptions: {
      enabled: !!record?.locationId,
    },
    resource: 'locations',
  })

  const { data: recUser } = useOne<IUser>({
    id: record?.createdBy || '',
    queryOptions: {
      enabled: !!record?.createdBy,
    },
    resource: 'users',
  })

  const { data: court } = useOne<ICourtResponse>({
    id: record?.courtId || '',
    queryOptions: {
      enabled: !!record?.courtId,
    },
    resource: 'courts',
  })

  const { data: instructor } = useOne<Instructor>({
    id: record?.instructorId || '',
    queryOptions: {
      enabled: !!record?.instructorId,
    },
    resource: 'instructors',
  })

  const { data: recClass } = useOne<IClass>({
    id: record?.classId || '',
    queryOptions: {
      enabled: !!record?.classId,
    },
    resource: 'classes',
  })

  // 🚀 Functions

  const handleCancel = () => {
    mutateUpdate({
      resource: `reservations/${record?.id}/cancel`,
      id: '',
      values: {},
      invalidates: ['all'],
    })
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={24} xl={24} xs={24}>
          <Show breadcrumb={<Breadcrumb />} resource="reservations">
            <Typography.Title level={3}>
              {record?.reservationType}
            </Typography.Title>
            <Typography.Paragraph>
              <Typography.Text italic>ID:</Typography.Text> {record?.id}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text italic>Created By:</Typography.Text>{' '}
              <Link to={`/users/show/${recUser?.data?.id}`}>
                {recUser?.data?.firstName} {recUser?.data?.lastName}
                {'; '}
                {recUser?.data?.email}
              </Link>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text italic underline>
                Time and date:
              </Typography.Text>{' '}
              {record?.reservationTimestampRange && (
                <>
                  {record?.reservationTimestampRange &&
                    dayjs(record?.reservationTimestampRange[0]).format(
                      'MMM D, YYYY, h:mm A'
                    )}
                  {' - '}
                  {record?.reservationTimestampRange &&
                    dayjs(record?.reservationTimestampRange[1]).format(
                      'h:mm A'
                    )}
                </>
              )}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text italic>Created at:</Typography.Text>{' '}
              {dayjs(record?.createdAt).format('dddd, MMMM D, YYYY h:mm A')}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text italic>Updated at:</Typography.Text>{' '}
              {dayjs(record?.updatedAt).format('dddd, MMMM D, YYYY h:mm A')}
            </Typography.Paragraph>
            {record?.linkedReservationId ? (
              <Typography.Paragraph>
                <Typography.Text italic>LinkedReservationId:</Typography.Text>{' '}
                {record.linkedReservationId}
              </Typography.Paragraph>
            ) : null}
            <Typography.Paragraph>
              <Typography.Text italic>Canceled at:</Typography.Text>{' '}
              {record?.canceledAt &&
                dayjs(record?.canceledAt).format('dddd, MMMM D, YYYY h:mm A')}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text italic>Location:</Typography.Text>{' '}
              <Link to={`/locations/show/${location?.data?.id}`}>
                {location?.data?.name}
              </Link>
            </Typography.Paragraph>
            {record?.courtId && record?.courts?.length < 1 && (
              <Typography.Paragraph>
                <Typography.Text italic>Court number:</Typography.Text>{' '}
                {court?.data?.courtNumber}
              </Typography.Paragraph>
            )}
            {record?.courts?.length && (
              <Typography.Paragraph>
                <Typography.Text italic>
                  Court number{record?.courts?.length > 1 ? 's' : ''}:{' '}
                  {CommaSeparatedCourts(record.courts)}
                </Typography.Text>
              </Typography.Paragraph>
            )}
            <Typography.Paragraph>
              <Typography.Text italic>Cost:</Typography.Text>{' '}
              {record?.reservationCost && record?.reservationCost / 100}{' '}
              {record?.reservationCostCurrency}
            </Typography.Paragraph>
            {record?.reservationType === ReservationType.court && (
              <>
                <Typography.Paragraph>
                  <Typography.Text italic>Paid:</Typography.Text>{' '}
                  {record?.paid ? '✅' : '❌'}
                </Typography.Paragraph>
              </>
            )}
            <Typography.Paragraph>
              <Typography.Text italic>Time status:</Typography.Text>{' '}
              {record?.timeStatus}
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text italic>Restricted to groups:</Typography.Text>{' '}
              {reservationGroups ? (
                <>
                  <ul>
                    {reservationGroups?.map((group) => (
                      <Link to={`/groups/show/${group.id}`}>
                        <li key={group.id}>{group.name}</li>
                      </Link>
                    ))}
                  </ul>
                </>
              ) : (
                `No membership restrictions`
              )}
            </Typography.Paragraph>
            <Divider />
            {record?.reservationType !== ReservationType.court && (
              <>
                <Typography.Paragraph>
                  <Typography.Text italic>Class:</Typography.Text>{' '}
                  <Link to={`/classes/show/${recClass?.data?.id}`}>
                    {recClass?.data?.name}
                  </Link>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <Typography.Text italic>Capacity:</Typography.Text>{' '}
                  {record?.capacity}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <Typography.Text italic>Instructor:</Typography.Text>{' '}
                  {instructor ? (
                    <>
                      ✅{' '}
                      <Link to={`/instructors/show/${instructor?.data?.id}`}>
                        show instructor's detail
                      </Link>
                    </>
                  ) : (
                    `⛔`
                  )}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <Typography.Text italic>Registered users:</Typography.Text>{' '}
                  <br></br>
                  <ol>
                    {record?.users.map((user) => (
                      <li>
                        <Link key={user.id} to={`/users/show/${user.id}`}>
                          {user.firstName} {user.lastName}
                        </Link>
                        <br></br>
                        <Typography.Text>
                          Guests: {user?.numberOfGuests}
                        </Typography.Text>
                        <br></br>
                        <Link
                          key={user?.reservationUserId}
                          to={`/user-reservations/show/${user?.reservationUserId}`}
                        >
                          See reservation detail
                        </Link>
                      </li>
                    ))}
                  </ol>
                </Typography.Paragraph>
              </>
            )}
            <Divider />
            {record?.canceledAt ? null : (
              <Popconfirm
                title="Cancel reservation"
                description="Are you sure to cancel this reservation? If this is Class or Open play reservation, it will be canceled for all users!"
                onConfirm={handleCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>
                  {record?.reservationType === ReservationType.court &&
                    ' ⚠️ Cancel user reservation'}
                  {record?.reservationType ===
                    ReservationType.programmedClassInstructor &&
                    '⚠ Cancel class reservation'}
                  {record?.reservationType ===
                    ReservationType.programmedClassOpenPlay &&
                    '⚠ Cancel open play reservation'}
                  {record?.reservationType === ReservationType.privateLesson &&
                    '⚠ Cancel private lesson reservation'}
                </Button>
              </Popconfirm>
            )}
          </Show>
        </Col>
      </Row>
    </>
  )
}
