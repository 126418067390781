import { Breadcrumb, Create, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useList } from '@refinedev/core'
import { Form, Input, InputNumber, Select } from 'antd'
import type { ISport, ISportRating } from 'interfaces'

export const SportRatingCreate: React.FC<IResourceComponentsProps> = () => {
  //🪝 HOOKS
  const { formProps, saveButtonProps } = useForm<ISportRating>()

  // 💿 DATA
  // fetch all sports
  const { data: sports } = useList<ISport>({ resource: 'sports' })

  return (
    <Create
      breadcrumb={<Breadcrumb />}
      resource="sport-ratings"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        initialValues={{
          isActive: true,
        }}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <Form.Item
          label="Select sport"
          name="sportId"
          rules={[{ message: 'Please select a sport' }]}
        >
          <Select>
            {sports?.data.map((sport) => (
              <Select.Option value={sport.id} key={sport.id}>
                {sport.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ message: "Please enter sport's rating name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ message: "Please enter sport's rating description" }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Enter rating" name="rating">
          <InputNumber />
        </Form.Item>
      </Form>
    </Create>
  )
}
