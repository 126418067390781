import { Collapse, Typography } from 'antd'
import dayjs from 'dayjs'
import { IReservation } from 'interfaces'
import { ShowCourse } from './showCourse'

type Props = {
  linkedReservations: IReservation[]
}

function ListCourses({ linkedReservations }: Props) {
  const uniqueLinkedReservationIds = [
    ...new Set(
      linkedReservations.map((reservation) => reservation.linkedReservationId)
    ),
  ] as string[]

  const openKeys = linkedReservations
    .reduce<(string | null)[]>(
      (acc, linkedReservation) => [
        ...acc,
        !linkedReservation.canceledAt
          ? linkedReservation.linkedReservationId
          : null,
      ],
      [] as string[]
    )
    .filter(Boolean) as string[]

  return (
    <Collapse expandIconPosition={'right'} defaultActiveKey={openKeys}>
      {uniqueLinkedReservationIds.map((linkedReservationId) => {
        const courseReservations = linkedReservations.filter(
          (reservation) =>
            reservation.linkedReservationId === linkedReservationId
        )
        const reservationsStartTimes = courseReservations.map((reservation) => {
          if (reservation.reservationTimestampRange) {
            return dayjs(reservation.reservationTimestampRange[0]).format(
              'MMMM D, YYYY h:mm A'
            )
          }
        })

        const sortedReservationsStartTimes = reservationsStartTimes.sort(
          (a, b) => (dayjs(a).isBefore(b) ? -1 : 1)
        )

        const courseStartDate = sortedReservationsStartTimes[0]
        const courseEndDate =
          sortedReservationsStartTimes[sortedReservationsStartTimes.length - 1]

        return (
          <Collapse.Panel
            header={
              courseStartDate && courseEndDate ? (
                <>
                  <Typography.Title level={3} style={{ marginTop: 12 }}>
                    Course with {courseReservations.length} sessions
                  </Typography.Title>
                  <Typography.Paragraph>
                    Starting on {courseStartDate} <br></br>
                    Ending on {courseEndDate}
                  </Typography.Paragraph>
                </>
              ) : (
                <Typography.Title level={3} style={{ marginTop: 12 }}>
                  Course canceled
                </Typography.Title>
              )
            }
            key={linkedReservationId}
          >
            <ShowCourse reservations={courseReservations} />
          </Collapse.Panel>
        )
      })}
    </Collapse>
  )
}

export default ListCourses
