import { SaveOutlined } from '@ant-design/icons'
import { Edit, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useCreate } from '@refinedev/core'
import { Button, Col, Form, Input, Modal, Row, Select, Tag } from 'antd'
import { useState } from 'react'

import type { Instructor, IUser } from 'interfaces'

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const [isNewInstructorModalOpen, setIsNewInstructorModalOpen] =
    useState(false)
  const { formProps, queryResult, saveButtonProps } = useForm<IUser>()
  const { form: formModal, formProps: formModalProps } = useForm<Instructor>()
  const { mutate: mutateCreate } = useCreate()
  const [tagInput, setTagInput] = useState<string>('')
  const [tags, setTags] = useState<string[]>([])

  const removeItemFromTags = (item: string) => {
    setTags(tags.filter((tag) => tag !== item))
  }

  const addItemToTags = (item: string) => {
    setTags([...tags, item])
  }

  const handleOkAddInstructor = () => {
    const newInstructor: Pick<
      Instructor,
      'longBio' | 'shortDescription' | 'userId' | 'tags'
    > = {
      // @ts-expect-error
      longBio: formModal.getFieldsValue().longBio,
      // @ts-expect-error
      shortDescription: formModal.getFieldsValue().shortDescription,
      tags: tags,
      userId: formProps.initialValues?.id,
    }

    mutateCreate({
      resource: 'instructors',
      values: {
        ...newInstructor,
      },
    })

    setIsNewInstructorModalOpen(false)
  }

  const handleCancelAddInstructor = () => {
    setIsNewInstructorModalOpen(false)
  }

  const showNewInstructorModal = () => {
    setIsNewInstructorModalOpen(true)
  }

  const email = queryResult?.data?.data.email
  const title = email ? `Edit User: ${email}` : null

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      saveButtonProps={saveButtonProps}
      title={title}
    >
      <Form
        {...formProps}
        initialValues={{
          isActive: true,
          ...formProps.initialValues,
        }}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <>
          <Row gutter={20}>
            <Col lg={8} xs={24} />
            <Col lg={16} xs={24}>
              <Row gutter={10}>
                <Col lg={12} xs={24}>
                  <Form.Item label="Role" name="role">
                    <Select
                      options={[
                        { label: 'User', value: 'user' },
                        { label: 'Admin', value: 'admin' },
                        { label: 'External Admin', value: 'external-admin' },
                      ]}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      showNewInstructorModal()
                    }}
                  >
                    Convert to instructor
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      </Form>
      <Modal
        okButtonProps={{ icon: <SaveOutlined />, type: 'primary' }}
        open={isNewInstructorModalOpen}
        title="Create new Instructor"
        onCancel={handleCancelAddInstructor}
        onOk={handleOkAddInstructor}
      >
        <Form layout="vertical" {...formModalProps}>
          <Form.Item required label="Description" name="shortDescription">
            <Input.TextArea />
          </Form.Item>
          <Form.Item required label="Bio" name="longBio">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Tags (Press enter to add a new tag)" name="tags">
            <Input
              value={tagInput}
              onChange={(e) => setTagInput(e.currentTarget.value)}
              onPressEnter={(e) => {
                e.preventDefault()
                addItemToTags(e.currentTarget.value)
                setTagInput('')
              }}
            />
            {tags?.map((tag) => (
              <Tag
                closable
                onClose={() => removeItemFromTags(tag)}
                style={{ marginTop: 10 }}
              >
                {tag}
              </Tag>
            ))}
          </Form.Item>
        </Form>
      </Modal>
    </Edit>
  )
}
