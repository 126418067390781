import { useList } from '@refinedev/core'
import { Button, Card, Spin } from 'antd'
import type { FC } from 'react'
import { useState } from 'react'

import type { ICourtResponse } from 'interfaces'

import { ActionsWrapper } from './actionsWrapper'
import { Court } from './court'
import { CreateCourtModal } from './createCourtModal'

interface ICourtsProps {
  locationId?: string
}

export const Courts: FC<ICourtsProps> = ({ locationId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const { data, isLoading } = useList<ICourtResponse>({
    queryOptions: {
      enabled: !!locationId,
    },
    resource: `courts?locationId=${locationId}`,
  })

  const allCourts = data?.data

  const courtsLength = data?.data.length

  return (
    <>
      <Spin spinning={isLoading}>
        <Card
          actions={[
            <ActionsWrapper>
              <Button key="add-court" type="primary" onClick={showModal}>
                Add Court
              </Button>
            </ActionsWrapper>,
          ]}
          style={{ marginTop: 32 }}
          title="Courts"
        >
          {data?.data
            .sort((a, b) => {
              return a.courtNumber < b.courtNumber ? -1 : 1
            })
            .map((court) => {
              return (
                <Court
                  allCourtsAtLocation={allCourts}
                  court={court}
                  key={court.id}
                />
              )
            })}
        </Card>
      </Spin>
      <CreateCourtModal
        courtsLength={courtsLength}
        isModalOpen={isModalOpen}
        locationId={locationId}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  )
}
