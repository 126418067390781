import React from 'react'
import { Breadcrumb, Create, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { Form, Input, InputNumber } from 'antd'
import type { IRegion } from 'interfaces'

export const RegionsCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IRegion>()

  return (
    <Create
      breadcrumb={<Breadcrumb />}
      resource="regions"
      saveButtonProps={saveButtonProps}
    >
      <Form
        {...formProps}
        initialValues={{
          isActive: true,
        }}
        layout="vertical"
        style={{ marginTop: 30 }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ message: 'Please enter a region name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Latitude (center)"
          name="lat"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Longitude (center)"
          name="lng"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Radius (miles)"
          name="radius"
          rules={[{ required: true }]}
        >
          <InputNumber defaultValue={50} min={0} />
        </Form.Item>
      </Form>
    </Create>
  )
}
