import { List, ShowButton, useTable } from '@refinedev/antd'
import { IResourceComponentsProps, useList } from '@refinedev/core'
import { Table } from 'antd'
import { ISport, ISportRating } from 'interfaces'

export const SportRatingsList: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const { tableProps } = useTable<ISportRating>({
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  })

  // fetch all sports
  const { data: sports } = useList<ISport>({ resource: 'sports' })

  return (
    <List canCreate createButtonProps={{ size: 'small' }}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="sportId"
          title="Sport"
          render={(sportId) => {
            const sportName = sports?.data.find(
              (sport) => sport.id === sportId
            )?.name

            return <span>{sportName}</span>
          }}
        />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column dataIndex="rating" title="Rating" />
        <Table.Column
          dataIndex="id"
          key="show"
          render={(_, record: ISportRating) => {
            return (
              <ShowButton
                hideText
                id={record.id}
                recordItemId={record.id}
                resource="sport-ratings"
                size="small"
              />
            )
          }}
          title="Detail"
        />
      </Table>
    </List>
  )
}
