import { Edit, useForm } from '@refinedev/antd'
import {
  IResourceComponentsProps, useList,
  useNavigation,
  useShow,
  useUpdate,
} from '@refinedev/core'
import { DatePicker, Form, Select } from 'antd'
import dayjs from 'dayjs'
import { ICourtResponse, IReservation, ISport } from 'interfaces'
import React, { useMemo } from 'react'

export const ReservationEdit: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const data = useShow<IReservation>()
  const { mutate: mutateUpdate } = useUpdate()
  const { show } = useNavigation()

  // 💿 DATA
  const record = data?.queryResult?.data?.data

  // 🚀 Functions
  const { formProps, saveButtonProps } = useForm<IReservation>({
    action: 'edit',
    redirect: 'show',
    resource: 'reservations',
  })

  // sports
  const { data: sports } = useList<ISport>({
    resource: 'sports',
  })

  // Courts
  const selectedLocationId = data.queryResult.data?.data.locationId;

  const { data: courts } = useList<ICourtResponse>({
    queryOptions: {
      enabled: !!selectedLocationId,
      queryKey: ['list', { locationId: selectedLocationId }],
    },

    resource: `courts?locationId=${selectedLocationId}`,
  })

  const sportId = data.queryResult.data?.data.sport?.id;
  const courtSports = useMemo(() => {
    const courtsData = courts?.data ?? []

    const filteredBySport = courtsData
        .flatMap((court) => court.sports)
        .filter((courtSport) => courtSport.sportId === sportId)

    return filteredBySport
  }, [courts])

  const startDate = dayjs(record?.reservationTimestampRange[0])
  const endDate = dayjs(record?.reservationTimestampRange[1])

  const handleOnFinish = async (values: any) => {
    const startDate = dayjs(values.date[0].$d as Date).format('YYYY-MM-DD')
    const startTime = dayjs(values.date[0].$d as Date).format('HH:mm:00')
    const endDate = dayjs(values.date[1].$d as Date).format('YYYY-MM-DD')
    const endTime = dayjs(values.date[1].$d as Date).format('HH:mm:00')
    mutateUpdate(
      {
        resource: 'reservations',
        id: record?.id!,
        values: {
          from: {
            date: startDate,
            time: startTime,
          },
          to: {
            date: endDate,
            time: endTime,
          },
          courtSportIds: values.courtSportIds,
        },
      },
      {
        onSuccess: () => {
          show('reservations', record!.id)
        },
      }
    )
  }

  return (
    <Edit resource="reservations" saveButtonProps={saveButtonProps}>
      <Form
        layout="vertical"
        {...formProps}
        initialValues={{
          date: [startDate, endDate],
          courtSportIds: record?.courtSportIds ?? [],
        }}
        onFinish={handleOnFinish}
      >
        <Form.Item label="Date" name="date">
          <DatePicker.RangePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            // @ts-ignore -- minuteStep is not in the type
            minuteStep={15}
          />
        </Form.Item>
        <Form.Item label="Court & Sport" name="courtSportIds">
          <Select mode="multiple" allowClear>
            {courtSports?.map((courtSport) => {
              const sportsData = sports?.data ?? []
              const courtsData = courts?.data ?? []

              const sportName =
                  sportsData.find((sport) => sport.id === courtSport.sportId)
                      ?.name ?? ''

              const courtNumber = courtsData.find(
                  (court) => court.id === courtSport.courtId
              )?.courtNumber

              return (
                  <Select.Option value={courtSport.id} key={courtSport.id}>
                    Court #{courtNumber} - {sportName}
                  </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </Form>
    </Edit>
  )
}
