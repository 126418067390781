import { List, ShowButton, useTable } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useDelete, useMany, useNavigation } from '@refinedev/core'
import { Space, Table, Typography } from 'antd'

import type { Instructor, IUser } from '../../interfaces'

export const InstructorsList: React.FC<IResourceComponentsProps> = () => {
  const { edit } = useNavigation()

  const { tableProps } = useTable<Instructor>({
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  })

  const userIds = tableProps?.dataSource?.map((user) => user.userId) ?? []

  const { data: users } = useMany<IUser>({
    ids: userIds,
    queryOptions: {
      enabled: !!userIds.length,
    },
    resource: 'users',
  })

  const { mutate: mutateDelete } = useDelete()

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="userId"
          render={(userId) => {
            const user = users?.data.find((user) => user.id === userId)

            return (
              <Space>
                <Typography.Text>
                  {user?.firstName} {user?.lastName}
                </Typography.Text>
              </Space>
            )
          }}
          title="Name"
        />

        <Table.Column<Instructor>
          key="shortDescription"
          render={(record: Instructor) => (
            <Space>
              <Typography.Text style={{ wordBreak: 'inherit' }}>
                {record.shortDescription}
              </Typography.Text>
            </Space>
          )}
          title="Short Description"
        />
        <Table.Column<Instructor> dataIndex="longBio" title="Bio" />
        <Table.Column<Instructor>
          dataIndex="id"
          key="show"
          render={(_, record: Instructor) => {
            return (
              <ShowButton
                hideText
                id={record.id}
                recordItemId={record.id}
                resource="instructors"
                size="small"
              />
            )
          }}
          title="Detail"
        />
      </Table>
    </List>
  )
}
