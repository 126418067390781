import { Breadcrumb, EditButton, Show } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useList, useOne, useShow } from '@refinedev/core'
import { Divider, Typography } from 'antd'

import type {
  IClass,
  ILocation,
  IReservation,
  ISport,
  IUser,
  Instructor,
} from 'interfaces'
import { useMemo } from 'react'
import ListCourses from './reservationViews/listCourses'
import { ListReservations } from './reservationViews/listReservations'

export const ClassesShow: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const data = useShow<IClass>()
  // 💿 DATA
  const record = data?.queryResult?.data?.data

  const linkedReservations = useMemo(() => {
    return (record?.reservations
      .map((reservation) => {
        if (reservation.linkedReservationId) {
          return reservation
        }
      })
      .filter(Boolean) ?? []) as IReservation[]
  }, [record])

  //  ✅ COMPONENTS

  const { data: location } = useOne<ILocation>({
    id: record?.locationId || '',
    queryOptions: {
      enabled: !!record?.locationId,
    },
    resource: 'locations',
  })

  const { data: instructor } = useOne<Instructor>({
    id: record?.instructorId || '',
    queryOptions: {
      enabled: !!record?.instructorId,
    },
    resource: 'instructors',
  })

  const { data: user } = useOne<IUser>({
    id: instructor?.data.userId || '',
    queryOptions: {
      enabled: !!instructor?.data.userId,
    },
    resource: 'users',
  })

  const { data: sports } = useList<ISport>({
    resource: 'sports',
  })

  const sportName = sports?.data.find(
    (sport) => sport.id === record?.sportId
  )?.name

  return (
    <>
      <Show
        breadcrumb={<Breadcrumb />}
        resource="class"
      >
        <Typography.Title level={3}>{record?.name}</Typography.Title>
        <Typography.Paragraph italic>Class Description:</Typography.Paragraph>
        <Typography.Paragraph>{record?.description}</Typography.Paragraph>
        <Typography.Paragraph italic>Location Name:</Typography.Paragraph>
        <Typography.Paragraph>{location?.data.name}</Typography.Paragraph>
        <Typography.Paragraph italic>Sport:</Typography.Paragraph>
        <Typography.Paragraph>{sportName}</Typography.Paragraph>
        <Typography.Paragraph italic>Instructor Name:</Typography.Paragraph>
        <Typography.Paragraph>
          {user?.data.firstName} {user?.data.lastName}
        </Typography.Paragraph>
        <Typography.Paragraph italic>Equipment included:</Typography.Paragraph>
        <Typography.Paragraph>
          {record?.equipmentIncluded ? 'Yes' : 'No'}
        </Typography.Paragraph>
        <Typography.Paragraph italic>Recommended Level:</Typography.Paragraph>
        <Typography.Paragraph>{record?.recommendedLevel}</Typography.Paragraph>
        <Typography.Paragraph italic>
          Enforce recommended level:
        </Typography.Paragraph>
        <Typography.Paragraph>
          {record?.enforceRecommendedLevel ? 'Yes' : 'No'}
        </Typography.Paragraph>
        <Typography.Paragraph italic>Price:</Typography.Paragraph>
        <Typography.Paragraph>
          {record?.price} {record?.currency}
        </Typography.Paragraph>
        <Typography.Paragraph italic>Allow guests:</Typography.Paragraph>
        <Typography.Paragraph>
          {record?.allowGuests ? 'Yes' : 'No'}
        </Typography.Paragraph>
        <Typography.Paragraph italic>Guest Price: </Typography.Paragraph>
        <Typography.Paragraph>
          {record?.guestPrice} {record?.currency}
        </Typography.Paragraph>
        <Typography.Paragraph italic>Type:</Typography.Paragraph>
        <Typography.Paragraph>{record?.type}</Typography.Paragraph>
        <Typography.Paragraph italic>
          Class reservation window:
        </Typography.Paragraph>
        <Typography.Paragraph>
          {record?.defaultReservationWindow} days
        </Typography.Paragraph>
        <Typography.Paragraph italic>Do Not Market?</Typography.Paragraph>
        <Typography.Paragraph>
          {record?.doNotMarket ? 'True' : 'False'}
        </Typography.Paragraph>

        <EditButton recordItemId={record?.id} />
      </Show>
      <Divider />
      {/* Show courses here - we want to render showCourse component 
      for each unique linkedReservationId in linkedReservations and then pass all the reservations with that particular linkedReservationId
      as a prop to showCourse component.  */}
      {linkedReservations.length > 0 && (
        <ListCourses linkedReservations={linkedReservations} />
      )}
      <Divider />
      <Divider style={{ marginBottom: '20px' }} />
      <ListReservations
        reservations={record?.reservations.filter(
          (reservation) => !reservation.linkedReservationId
        )}
      />
    </>
  )
}
