import {
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { List, ShowButton, useTable } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useDelete, useNavigation, useRouterContext } from '@refinedev/core'
import { Dropdown, Menu, Table, Tag } from 'antd'

import type { IOrganization } from '../../interfaces'
import { IOrganizationLocation, IRuleResponse } from '../../interfaces'
import React from 'react'

export const OrganizationsList: React.FC<IResourceComponentsProps> = () => {
  const { edit, show } = useNavigation()
  const { Link } = useRouterContext()

  const { tableProps } = useTable<IOrganization>({
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  })

  const { mutate: mutateDelete } = useDelete()

  const moreMenu = (id: string) => (
    <Menu
      mode="vertical"
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      <Menu.Item
        icon={
          <EyeOutlined
            style={{
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        key="detail"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          show('organizations', id)
        }}
      >
        Detail
      </Menu.Item>
      <Menu.Item
        icon={
          <EditOutlined
            style={{
              color: '#52c41a',
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        key="edit"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          edit('organizations', id)
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        icon={
          <CloseCircleOutlined
            style={{
              color: '#EE2A1E',
              fontSize: 17,
            }}
          />
        }
        key="delete"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          mutateDelete({
            id,
            mutationMode: 'undoable',
            resource: 'organizations',
          })
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column dataIndex="slug" title="Slug" />
        <Table.Column dataIndex="slug" title="Slug" />
        <Table.Column
          dataIndex="locations"
          title="Locations"
          render={(locations) => {
            return (
              <>
                {locations?.map((location: IOrganizationLocation) => (
                  <Tag color="blue" key={location.id}>
                    {location.name}
                  </Tag>
                ))}
              </>
            )
          }}
        />
        <Table.Column<IOrganization>
          dataIndex="actions"
          fixed="right"
          key="actions"
          render={(_, record: IOrganization) => (
            <Dropdown overlay={moreMenu(record.id)} trigger={['click']}>
              <MoreOutlined
                style={{ fontSize: 24 }}
                onClick={(e) => e.stopPropagation()}
              />
            </Dropdown>
          )}
          title="Actions"
        />
      </Table>
    </List>
  )
}
