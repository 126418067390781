import { useRouterContext } from '@refinedev/core'
import { Card, Typography } from 'antd'
import dayjs from 'dayjs'
import { IReservation } from 'interfaces'
import { FC } from 'react'

export const ListReservations: FC<{
  reservations?: IReservation[]
}> = ({ reservations }) => {
  if (!reservations) {
    return null
  }

  const { Link } = useRouterContext()

  return (
    <>
      {reservations.length > 0 && (
        <Typography.Title level={2}>Other Reservations</Typography.Title>
      )}
      {reservations.map((reservation) => {
        return (
          <Link to={`/reservations/show/${reservation?.id}`}>
            <Card
              key={reservation.id}
              style={{
                marginTop: 12,
              }}
              title={`[${reservation.reservationType}] - ${reservation.id}`}
            >
              <Typography.Paragraph>
                {reservation.reservationTimestampRange ? (
                  <>
                    {dayjs(reservation.reservationTimestampRange[0]).format(
                      'MMMM D, YYYY h:mm A'
                    )}{' '}
                    -{' '}
                    {dayjs(reservation.reservationTimestampRange[1]).format(
                      'MMMM D, YYYY h:mm A'
                    )}
                  </>
                ) : (
                  `Reservation canceled at ${dayjs(
                    reservation.canceledAt
                  ).format('MMMM D, YYYY h:mm A')}`
                )}
              </Typography.Paragraph>
            </Card>
          </Link>
        )
      })}
    </>
  )
}
