import { getApps, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const apps = getApps()

const app =
  apps.length === 0
    ? initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      })
    : apps[0]

export const firebaseAuth = getAuth(app)
