import {
  BankOutlined,
  CalendarOutlined,
  CompassOutlined,
  ExceptionOutlined,
  GlobalOutlined,
  IdcardOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  ErrorComponent,
  Layout,
  notificationProvider,
  ReadyPage,
} from '@refinedev/antd'
import '@refinedev/antd/dist/reset.css'
import { Refine } from '@refinedev/core'
import routerProvider from '@refinedev/react-router-v6/legacy'
import { createDataProvider } from 'api/dataProvider'
import { LoginPage } from 'components/auth/login'
import {
  ClassesCreate,
  ClassesEdit,
  ClassesList,
  ClassesShow,
} from 'components/classes'
import {
  GroupsCreate,
  GroupsEdit,
  GroupsList,
  GroupsShow,
} from 'components/groups'
import {
  InstructorsEdit,
  InstructorsList,
  InstructorsShow,
} from 'components/instructors'
import {
  LocationList,
  LocationsCreate,
  LocationsEdit,
  LocationsShow,
} from 'components/locations'
import { ReservationShow, ReservationsList } from 'components/reservations'
import { RulesCreate, RulesEdit, RulesShow } from 'components/rules'
import { RulesList } from 'components/rules/list'
import { Sider } from 'components/sider'
import {
  SportRatingCreate,
  SportRatingEdit,
  SportRatingShow,
  SportRatingsList,
} from 'components/sport-ratings'
import { UserReservationShow } from 'components/user-reservations'
import { UserEdit, UserShow, UsersList } from 'components/users'
import { authProvider } from './api/authProvider'
import {
  RegionsCreate,
  RegionsEdit,
  RegionsList,
  RegionsShow,
} from './components/regions'
import {
  OrganizationsCreate,
  OrganizationsEdit,
  OrganizationsList,
  OrganizationsShow,
} from './components/organizations'
import { ReservationEdit } from "./components/reservations/edit";
import { GlobalCategoriesList } from "components/global-categories";
import { GlobalActivitiesList } from "components/global-activities";
import { CategoriesList } from "components/categories";
import { ActivitiesList } from "components/activities";
import { GlobalCategoriesEdit } from "components/global-categories/edit";
import { GlobalActivitiesEdit } from "components/global-activities/edit";
import { ActivitiesEdit } from "components/activities/edit";
import { CategoriesEdit } from "components/categories/edit";
import { GlobalCategoriesCreate } from "components/global-categories/create";
import { GlobalActivitiesCreate } from "components/global-activities/create";
import { CategoriesCreate } from "components/categories/create";
import { ActivitiesCreate } from "components/activities/create";

function App() {
  return (
    <Refine
      dataProvider={createDataProvider(
        `${process.env.REACT_APP_API_URL}/admin`
      )}
      notificationProvider={notificationProvider}
      Layout={Layout}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      Sider={Sider}
      resources={[
        {
          name: 'locations',
          list: LocationList,
          create: LocationsCreate,
          edit: LocationsEdit,
          show: LocationsShow,
          icon: <CompassOutlined />,
        },
        {
          name: 'users',
          list: UsersList,
          edit: UserEdit,
          show: UserShow,
          icon: <UserOutlined />,
        },
        {
          name: 'reservations',
          list: ReservationsList,
          show: ReservationShow,
          edit: ReservationEdit,
          icon: <CalendarOutlined />,
        },
        {
          name: 'user-reservations',
          show: UserReservationShow,
        },
        {
          name: 'instructors',
          list: InstructorsList,
          show: InstructorsShow,
          edit: InstructorsEdit,
          icon: <IdcardOutlined />,
        },
        {
          name: 'classes',
          meta: {
            label: 'Programming',
          },
          list: ClassesList,
          show: ClassesShow,
          edit: ClassesEdit,
          create: ClassesCreate,
          canDelete: true,
          icon: <CalendarOutlined />,
        },
        {
          name: 'groups',
          list: GroupsList,
          show: GroupsShow,
          create: GroupsCreate,
          edit: GroupsEdit,
          icon: <UsergroupAddOutlined />,
        },
        {
          name: 'rules',
          list: RulesList,
          show: RulesShow,
          create: RulesCreate,
          edit: RulesEdit,
          icon: <ExceptionOutlined />,
        },
        {
          name: 'sports',
        },
        {
          name: 'sport-ratings',
          list: SportRatingsList,
          show: SportRatingShow,
          create: SportRatingCreate,
          edit: SportRatingEdit,
        },
        {
          name: 'regions',
          list: RegionsList,
          show: RegionsShow,
          edit: RegionsEdit,
          create: RegionsCreate,
          icon: <GlobalOutlined />,
        },
        {
          name: 'organizations',
          list: OrganizationsList,
          show: OrganizationsShow,
          create: OrganizationsCreate,
          edit: OrganizationsEdit,
          icon: <BankOutlined />,
        },
        {
          name: 'global-categories',
          list: GlobalCategoriesList,
          edit: GlobalCategoriesEdit,
          create: GlobalCategoriesCreate,
          icon: <BankOutlined />,
        },
        {
          name: 'global-activities',
          list: GlobalActivitiesList,
          edit: GlobalActivitiesEdit,
          create: GlobalActivitiesCreate,
          icon: <BankOutlined />,
        },
        {
          name: 'categories',
          list: CategoriesList,
          edit: CategoriesEdit,
          create: CategoriesCreate,
          icon: <BankOutlined />,
        },
        {
          name: 'activities',
          list: ActivitiesList,
          edit: ActivitiesEdit,
          create: ActivitiesCreate,
          icon: <BankOutlined />,
        },
      ]}
      legacyRouterProvider={{
        ...routerProvider,
        routes: [
          {
            path: '/login',
            element: <LoginPage />,
          },
        ],
      }}
      legacyAuthProvider={authProvider}
      LoginPage={LoginPage}
    ></Refine>
  )
}

export default App
