export const dayOfWeekOptions = [
  {
    label: 'Monday',
    value: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
  },
  {
    label: 'Wednesday',
    value: 3,
  },
  {
    label: 'Thursday',
    value: 4,
  },
  {
    label: 'Friday',
    value: 5,
  },
  {
    label: 'Saturday',
    value: 6,
  },
  {
    label: 'Sunday',
    value: 0,
  },
]

const generateTimeOptions = (): Array<{ label: string; value: string }> => {
  const timeOptions: Array<{ label: string; value: string }> = []

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const timeString = `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}:00`

      timeOptions.push({ label: timeString, value: timeString })
    }
  }

  return timeOptions
}

export const timeOptions = generateTimeOptions()
