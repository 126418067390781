import { List, ShowButton, useTable } from '@refinedev/antd'
import { IResourceComponentsProps, useMany } from '@refinedev/core'
import { Table } from 'antd'
import { IGroup, IOrganization } from 'interfaces'

export const GroupsList: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const { tableProps } = useTable<IGroup>({
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  })

  // 💿 DATA
  const record = tableProps.dataSource

  const { data: groups } = useMany<IGroup>({
    ids: record?.map((group) => group.id) ?? [],
    queryOptions: {
      enabled: !!record?.length,
    },
    resource: 'groups',
  })

  return (
    <List canCreate createButtonProps={{ size: 'small' }}>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column dataIndex="groupType" title="Group Type" />
        <Table.Column
          dataIndex="organization"
          title="Organization"
          render={(organization: IOrganization) => {
            return organization?.name
          }}
        />
        <Table.Column dataIndex="eligibility" title="Eligibility" />
        <Table.Column
          dataIndex="id"
          key="show"
          render={(_, record: IGroup) => {
            return (
              <ShowButton
                hideText
                id={record.id}
                recordItemId={record.id}
                resource="groups"
                size="small"
              />
            )
          }}
          title="Detail"
        />
      </Table>
    </List>
  )
}
