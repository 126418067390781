import { Edit, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { Form, Input, InputNumber } from 'antd'

import type { IRegion } from 'interfaces'
export const RegionsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IRegion>({
    action: 'edit',
    redirect: 'show',
    resource: 'regions',
  })

  return (
    <Edit canDelete resource="regions" saveButtonProps={saveButtonProps}>
      <Form layout="vertical" {...formProps}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ message: 'Please enter a name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Latitude"
          name="lat"
          rules={[{ message: 'Please enter a latitude' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Longitude"
          name="lng"
          rules={[{ message: 'Please enter a longitude' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Radius" name="radius">
          <InputNumber />
        </Form.Item>
      </Form>
    </Edit>
  )
}
