import React from 'react'
import { Show } from '@refinedev/antd'
import { ILocation, IRegion } from '../../interfaces'
import { useRouterContext, useShow } from '@refinedev/core'
import { Typography } from 'antd'

export const RegionsShow: React.FC = () => {
  const { queryResult } = useShow<IRegion>({ resource: 'regions' })
  const { Link } = useRouterContext()
  const record = queryResult?.data?.data

  return (
    <Show resource="regions">
      <Typography.Title level={3}>{record?.name}</Typography.Title>
      <Typography.Paragraph>
        Created: {new Date(record?.createdAt ?? '').toLocaleString()}
      </Typography.Paragraph>
      <Typography.Paragraph>
        Last Updated: {new Date(record?.updatedAt ?? '').toLocaleString()}
      </Typography.Paragraph>
      <Typography.Paragraph>Lat: {record?.lat}</Typography.Paragraph>
      <Typography.Paragraph>Lng: {record?.lng}</Typography.Paragraph>
      <Typography.Paragraph>
        Radius: {record?.radius} miles
      </Typography.Paragraph>
      <Typography.Title style={{ marginTop: 20 }} level={5}>
        Locations
      </Typography.Title>
      {record?.locations?.map((location: ILocation) => {
        return (
          <Link to={`/locations/show/${location.id}`}>{location.name}</Link>
        )
      })}
    </Show>
  )
}
