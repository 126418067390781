import { List, ShowButton, useTable } from '@refinedev/antd'
import type { HttpError, IResourceComponentsProps } from '@refinedev/core'
import { useList, useMany } from '@refinedev/core'
import { Space, Table, Typography } from 'antd'

import FilterAccordion from 'components/common/FilterAccordion'
import { RelationField } from 'components/common/RelationField'
import dayjs from 'dayjs'
import type {
  IClass,
  ILocation,
  IReservation,
  ISport,
  Instructor,
} from '../../interfaces'
import { Filter, FilterValues, onSearch } from './components/Filter'
import { getInstructorName } from './utils/getInstructorName'

export const ClassesList: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const { tableProps, searchFormProps } = useTable<
    IClass,
    HttpError,
    FilterValues
  >({
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
    onSearch,
  })

  // 💿 DATA
  const records = tableProps.dataSource

  const locationsIds =
    tableProps?.dataSource?.map((location) => location.locationId) ?? []

  const uniqueLocationsIds = [...new Set(locationsIds)]

  const { data: locations } = useMany<ILocation>({
    ids: uniqueLocationsIds,
    queryOptions: {
      enabled: !!uniqueLocationsIds.length,
    },
    resource: 'locations',
  })

  const instructorIds =
    tableProps?.dataSource
      ?.map((instructor) => instructor.instructorId)
      .filter((instructorId) => instructorId !== null) ?? []

  const uniqueInstructorIds = [...new Set(instructorIds)]

  const { data: instructors } = useMany<Instructor>({
    ids: uniqueInstructorIds,
    queryOptions: {
      enabled: !!uniqueInstructorIds.length,
    },
    resource: 'instructors',
  })

  const { data: sports } = useList<ISport>({
    resource: 'sports',
  })

  return (
    <List canCreate createButtonProps={{ size: 'small' }}>
      <FilterAccordion>
        <Filter
          formProps={searchFormProps}
          instructors={instructors?.data ?? []}
        />
      </FilterAccordion>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="type" title="Type" />
        <Table.Column
          dataIndex="instructorId"
          render={(instructorId) => {
            const instructor = instructors?.data.find(
              (instructor) => instructor.id === instructorId
            )

            return (
              <RelationField<Instructor>
                id={instructorId}
                resource="instructors"
                value={getInstructorName(instructor)}
              />
            )
          }}
          title="Instructor"
        />
        <Table.Column
          dataIndex="locationId"
          render={(locationId) => {
            const location = locations?.data.find(
              (location) => location.id === locationId
            )

            return (
              <RelationField<ILocation>
                id={locationId}
                resource="locations"
                value={location?.name ?? ''}
              />
            )
          }}
          title="Location"
        />
        <Table.Column
          dataIndex="sportId"
          render={(sportId) => {
            const sportName = sports?.data.find(
              (sport) => sport.id === sportId
            )?.name

            return (
              <Space>
                <Typography.Text>{sportName}</Typography.Text>
              </Space>
            )
          }}
          title="Sport"
        />
        <Table.Column
          dataIndex="reservations"
          title="Last Reservation"
          render={(reservations: IReservation[]) => {
            const lastReservation = reservations
              .filter((a) => !!a.canceledAt)
              .sort((a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)))[0]

            return lastReservation ? (
              <RelationField<IReservation>
                id={lastReservation.id}
                resource="reservations"
                value={dayjs(lastReservation.createdAt).format('LLL')}
              />
            ) : null
          }}
        />

        <Table.Column
          dataIndex="id"
          key="show"
          render={(_, record: IClass) => {
            return (
              <ShowButton
                hideText
                id={record.id}
                recordItemId={record.id}
                resource="classes"
                size="small"
              />
            )
          }}
          title="Detail"
        />
      </Table>
    </List>
  )
}
