import {
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons'
import { List, useTable } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useDelete, useNavigation, useRouterContext } from '@refinedev/core'
import { Dropdown, Menu, Table, Typography } from 'antd'

import type { ILocation, IRegion } from '../../interfaces'

export const RegionsList: React.FC<IResourceComponentsProps> = () => {
  const { edit, show } = useNavigation()
  const { Link } = useRouterContext()

  const { tableProps } = useTable<IRegion>({
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  })

  const { mutate: mutateDelete } = useDelete()

  const moreMenu = (id: string) => (
    <Menu
      mode="vertical"
      onClick={({ domEvent }) => domEvent.stopPropagation()}
    >
      <Menu.Item
        icon={
          <EyeOutlined
            style={{
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        key="detail"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          show('regions', id)
        }}
      >
        Detail
      </Menu.Item>
      <Menu.Item
        icon={
          <EditOutlined
            style={{
              color: '#52c41a',
              fontSize: 17,
              fontWeight: 500,
            }}
          />
        }
        key="edit"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          edit('regions', id)
        }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        icon={
          <CloseCircleOutlined
            style={{
              color: '#EE2A1E',
              fontSize: 17,
            }}
          />
        }
        key="delete"
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: 15,
          fontWeight: 500,
        }}
        onClick={() => {
          mutateDelete({
            id,
            mutationMode: 'undoable',
            resource: 'regions',
          })
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column dataIndex="lat" title="Latitude" />
        <Table.Column dataIndex="lng" title="Longitude" />
        <Table.Column dataIndex="radius" title="Radius" />
        <Table.Column
          dataIndex="locations"
          title="Locations"
          render={(locations) => {
            return locations.map((location: ILocation) => {
              return (
                <Typography.Text style={{ padding: 8 }} key={location.id}>
                  <Link to={`/locations/show/${location.id}`}>
                    {location.name}
                  </Link>
                </Typography.Text>
              )
            })
          }}
        />

        <Table.Column<IRegion>
          dataIndex="actions"
          fixed="right"
          key="actions"
          render={(_, record: IRegion) => (
            <Dropdown overlay={moreMenu(record.id)} trigger={['click']}>
              <MoreOutlined
                style={{ fontSize: 24 }}
                onClick={(e) => e.stopPropagation()}
              />
            </Dropdown>
          )}
          title="Actions"
        />
      </Table>
    </List>
  )
}
