import {
  useCustomMutation,
  useInvalidate,
  useList,
  useRouterContext,
} from '@refinedev/core'
import { ILocation, IRegion } from '../../../interfaces'
import { Button, Card, Spin, Typography } from 'antd'

export const Regions = ({ location }: { location?: ILocation }) => {
  const { Link } = useRouterContext()
  const invalidate = useInvalidate()
  const { data, isLoading } = useList<IRegion>({
    resource: 'regions',
  })

  const linkedRegions = []
  const unlinkedRegions = []

  for (const region of data?.data ?? []) {
    const locationIds = region.locations?.map(({ id }) => id) ?? []

    if (location?.id && locationIds.includes(location.id)) {
      linkedRegions.push(region)
    } else {
      unlinkedRegions.push(region)
    }
  }

  const { mutate } = useCustomMutation({
    mutationOptions: {
      onSettled: () => {
        invalidate({
          resource: 'regions',
          invalidates: ['list'],
        })
      },
    },
  })

  const linkRegion = ({ regionId }: { regionId?: string }) => {
    mutate({
      url: `regions/${regionId}/locations/`,
      method: 'post',
      values: { locationId: location?.id },
    })
  }
  const unlinkRegion = ({ regionId }: { regionId?: string }) => {
    mutate({
      url: `regions/${regionId}/locations/${location?.id}`,
      method: 'delete',
      values: {},
    })
  }

  const linkedRegionsList = linkedRegions.map((region) => {
    return (
      <div key={region.id}>
        <Link to={`/regions/show/${region.id}`}>{region.name}</Link>
        <Button
          style={{ marginLeft: 10 }}
          danger
          onClick={() => {
            unlinkRegion({ regionId: region.id })
          }}
        >
          Unlink
        </Button>
      </div>
    )
  })

  const unlinkedRegionsList = unlinkedRegions.map((region) => {
    return (
      <div key={region.id}>
        <Link to={`/regions/show/${region.id}`}>{region.name}</Link>
        <Button
          style={{ marginLeft: 10 }}
          type="primary"
          onClick={() => {
            linkRegion({ regionId: region.id })
          }}
        >
          Link
        </Button>
      </div>
    )
  })

  return (
    <Spin spinning={isLoading}>
      <Card style={{ marginTop: 20 }} title="Regions">
        <Typography.Title level={5}>Linked Regions</Typography.Title>
        {linkedRegionsList.length > 0 ? linkedRegionsList : <p>None</p>}

        <Typography.Title style={{ marginTop: 20 }} level={5}>
          Unlinked Regions
        </Typography.Title>
        {unlinkedRegionsList.length > 0 ? unlinkedRegionsList : <p>None</p>}
      </Card>
    </Spin>
  )
}
