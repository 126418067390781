import { List, ShowButton, useTable } from '@refinedev/antd'
import {
  CrudFilters,
  GetListResponse,
  HttpError,
  IResourceComponentsProps,
  useList,
} from '@refinedev/core'
import {
  Button,
  Col,
  Form,
  FormProps,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd'
import FilterAccordion from 'components/common/FilterAccordion'
import { RelationField } from 'components/common/RelationField'
import dayjs from 'dayjs'
import {
  IClass,
  ICourtResponse,
  ILocation,
  IReservation,
  IReservationFilterVariables,
  IUser,
} from 'interfaces'
import { ReservationType, getValuesOf } from 'utils/enums'
import { CommaSeparatedCourts } from 'utils/helpers'

export const ReservationsList: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const { tableProps, searchFormProps } = useTable<
    IReservation,
    HttpError,
    IReservationFilterVariables
  >({
    initialSorter: [
      {
        field: 'createdBy',
        order: 'asc',
      },
    ],
    onSearch: async (params) => {
      const filters: CrudFilters = []
      const { createdByEmail, reservationType, createdAt } = params

      const createdByUserId = users?.data.find(
        (user) => user.email === createdByEmail
      )?.id

      filters.push({
        field: 'reservationType',
        operator: 'eq',
        value: reservationType,
      })

      filters.push({
        field: 'createdBy',
        operator: 'eq',
        value: createdByUserId,
      })

      filters.push(
        {
          field: 'createdAt',
          operator: 'gte',
          value: createdAt
            ? createdAt[0].startOf('day').toISOString()
            : undefined,
        },
        {
          field: 'createdAt',
          operator: 'lte',
          value: createdAt
            ? createdAt[1].endOf('day').toISOString()
            : undefined,
        }
      )

      return filters
    },
    syncWithLocation: false,
  })

  // 💿 DATA
  // need to fetch and combine user
  const { data: users } = useList<IUser>({
    resource: 'users',
  })

  // need to fetch and combine location
  const { data: locations } = useList<ILocation>({
    resource: 'locations',
  })
  // need to fetch and combine court
  const { data: courts } = useList<ICourtResponse>({
    resource: 'courts',
  })

  // need to fetch and combine class
  const { data: classes } = useList<IClass>({
    resource: 'classes',
  })

  return (
    <List>
      <FilterAccordion>
        <Filter formProps={searchFormProps} users={users} />
      </FilterAccordion>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="reservationType"
          title="Reservation Type"
          render={(value) => value}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(createdAt) => {
            return (
              <Space>
                <Typography.Text>
                  {dayjs(createdAt).format('LLL')}
                </Typography.Text>
              </Space>
            )
          }}
        />
        <Table.Column
          dataIndex="createdBy"
          render={(createdBy) => {
            const user = users?.data.find((user) => user.id === createdBy)

            return !!user ? (
              <RelationField<IUser>
                id={user?.id}
                resource="users"
                value={user?.email ?? ''}
              />
            ) : null
          }}
          title="Created By"
        />
        <Table.Column
          dataIndex="locationId"
          render={(locationId) => {
            const location = locations?.data.find(
              (location) => location.id === locationId
            )
            return (
              <RelationField<ILocation>
                id={locationId}
                resource="users"
                value={location?.name ?? ''}
              />
            )
          }}
          title="Location"
        />
        <Table.Column
          dataIndex="linkedReservationId"
          title="Linked?"
          render={(linkedReservationId) => {
            if (linkedReservationId) {
              return <>✅</>
            } else {
              return null
            }
          }}
        />
        <Table.Column
          dataIndex="courts"
          render={(courts) => (
            <Space>
              <Typography.Text>{CommaSeparatedCourts(courts)}</Typography.Text>
            </Space>
          )}
          title="Court(s)"
        />
        <Table.Column
          dataIndex="classId"
          render={(classId) => {
            const recClass = classes?.data.find(
              (recClass) => recClass.id === classId
            )
            return (
              <RelationField<IClass>
                id={classId}
                resource="classes"
                value={recClass?.name ?? ''}
              />
            )
          }}
          title="Class"
        />

        <Table.Column dataIndex="timeStatus" title="Time Status" />
        <Table.Column
          dataIndex="reservationCost"
          title="Cost $"
          render={(reservationCost) => {
            return <Typography.Text> {reservationCost / 100}</Typography.Text>
          }}
        />
        <Table.Column
          dataIndex="reservationTimestampRange"
          title="Time and date"
          render={(reservationTimestampRange) => {
            if (reservationTimestampRange) {
              return (
                <Typography.Text>
                  <>
                    {dayjs(reservationTimestampRange[0]).format(
                      'MMM D, YYYY h:mm A'
                    )}
                    {' - '}
                    {dayjs(reservationTimestampRange[1]).format('h:mm A')}
                  </>
                </Typography.Text>
              )
            } else {
              return null
            }
          }}
        />
        <Table.Column
          dataIndex="id"
          key="show"
          render={(_, record: IReservation) => {
            return (
              <ShowButton
                hideText
                id={record.id}
                recordItemId={record.id}
                resource="reservations"
                size="small"
              />
            )
          }}
          title="Detail"
        />
      </Table>
    </List>
  )
}

const Filter: React.FC<{
  formProps: FormProps
  users: GetListResponse<IUser> | undefined
}> = (props) => {
  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col xs={6} xl={6} md={8}>
          <Form.Item label="Reservation type" name="reservationType">
            <Select
              allowClear
              placeholder="Select reservation type"
              options={getValuesOf(ReservationType)?.map((value) => ({
                label: value,
                value,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={6} xl={6} md={8}>
          <Form.Item label="Created by" name="createdByEmail">
            <Select allowClear placeholder="Search user email" showSearch>
              {/* need to filter by createdBy uuid from dataSource, which is actually user id from users data
              , but we need to match the data and allow search by email, which is unique for each user id
              */}
              {props.users?.data
                ?.sort((a, b) => a.email.localeCompare(b.email))
                .map((user) => (
                  <Select.Option key={user.id} value={user.email}>
                    {user.email}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Filter
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
