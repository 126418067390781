import { Breadcrumb, Edit, getValueFromEvent, useForm } from '@refinedev/antd'
import type { IResourceComponentsProps } from '@refinedev/core'
import { useCustomMutation, useList } from '@refinedev/core'
import {
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
  Upload,
} from 'antd'
import { useMemo } from 'react'

import {
  IPreSignedRequest,
  IPreSignedResponse,
  ISport,
  type IClass,
  type Instructor,
} from 'interfaces'
import { CreateRecurringReservations } from './reservationViews/createRecurringReservations'
import { CreateReservation } from './reservationViews/createReservation'

export const ClassesEdit: React.FC<IResourceComponentsProps> = () => {
  const { mutateAsync } = useCustomMutation<IPreSignedResponse>()

  const { formProps, queryResult, saveButtonProps } = useForm<IClass>({
    action: 'edit',
    redirect: 'show',
    resource: 'classes',
  })

  const { data: instructors } = useList<Instructor>({
    resource: 'instructors',
  })

  const instructorsData = instructors?.data ?? []
  instructorsData.sort((a, b) => {
    const aLastName = (a.user?.lastName ?? '').toLowerCase()
    const bLastName = (b.user?.lastName ?? '').toLowerCase()

    return aLastName > bLastName ? 1 : -1
  })

  const { data: sports } = useList<ISport>({
    resource: 'sports',
  })

  const classData = queryResult?.data?.data

  const classInitialValues = useMemo(() => {
    if (!classData) {
      return {}
    }

    const values: Omit<IClass, 'reservations'> = {
      allowGuests: classData.allowGuests,
      currency: classData.currency,
      description: classData.description,
      enforceRecommendedLevel: classData.enforceRecommendedLevel,
      guestCurrency: classData.guestCurrency,
      guestPrice: classData.guestPrice,
      instructorId: classData.instructorId,
      locationId: classData.locationId,
      name: classData.name,
      price: classData.price,
      recommendedLevel: classData.recommendedLevel,
      sportId: classData.sportId,
      type: classData.type,
      id: classData.id,
      defaultReservationWindow: classData.defaultReservationWindow,
      equipmentIncluded: classData.equipmentIncluded,
      doNotMarket: Boolean(classData.doNotMarket),
    }

    return values
  }, [classData])

  const getUploadAction = async (
    args: File,
    type: 'class152x240' | 'class240x140' | 'class152x89'
  ) => {
    let { name: key, type: contentType } = args

    // make sure we always use jpeg, as content type for .jpg is image/jpeg
    if (key.split('.').pop() === 'jpg') key = key.replace('jpg', 'jpeg')

    const values: IPreSignedRequest = {
      contentType,
      key,
      type,
    }

    const url = `classes/${queryResult?.data?.data.id}/image`

    const { data } = await mutateAsync({
      method: 'post',
      url,
      values,
    })

    return data.preSignedUrl
  }

  return (
    <>
      <Edit
        canDelete
        breadcrumb={<Breadcrumb />}
        resource="classes"
        saveButtonProps={saveButtonProps}
      >
        <Form
          layout="vertical"
          {...formProps}
          initialValues={classInitialValues}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ message: 'Please enter a name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[{ message: 'Please enter a description' }]}
          >
            <Input.TextArea allowClear />
          </Form.Item>
          <Form.Item
            label="Instructor"
            name="instructorId"
            rules={[{ message: 'Please enter an instructor' }]}
          >
            <Select allowClear>
              {instructorsData.map((instructor: Instructor) => (
                <Select.Option key={instructor.id} value={instructor.id}>
                  <>
                    <strong>
                      {instructor.user?.firstName} {instructor.user?.lastName}
                    </strong>{' '}
                    {instructor.user?.email}
                  </>
                </Select.Option>
              ))}
              <Select.Option value={null}>None</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Sport"
            name="sportId"
            rules={[{ message: 'Please enter a sport' }]}
          >
            <Select>
              {sports?.data.map((sport) => (
                <Select.Option value={sport.id}>{sport.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Price" name="price">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Currency" name="currency">
            <Select style={{ maxWidth: 200 }}>
              <Select.Option label="USD" value="USD">
                USD
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Equipment included?" name="equipmentIncluded">
            <Select>
              <Select.Option value>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Recommended level"
            name="recommendedLevel"
            rules={[{ message: 'Please enter recommended level' }]}
          >
            <Select>
              <Select.Option value="beginners">beginner</Select.Option>
              <Select.Option value="intermediate">intermediate</Select.Option>
              <Select.Option value="advanced">advanced</Select.Option>
              <Select.Option value="first-timers">first-timers</Select.Option>
              <Select.Option value="all">all</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Enforce recommended level?"
            name="enforceRecommendedLevel"
          >
            <Select>
              <Select.Option value>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Allow guests?" name="allowGuests">
            <Select style={{ maxWidth: 200 }}>
              <Select.Option value>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Guest price" name="guestPrice">
            <InputNumber />
          </Form.Item>
          <Form.Item label="Guest currency" name="guestCurrency">
            <Select allowClear style={{ maxWidth: 200 }}>
              <Select.Option value="USD">USD</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[{ message: 'Please enter class type' }]}
          >
            <Select>
              <Select.Option value="open-play">open-play</Select.Option>
              <Select.Option value="programmed">programmed</Select.Option>
              <Select.Option value="private-lesson">
                private-lesson
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Default reservation window"
            name="defaultReservationWindow"
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Do not market" name="doNotMarket">
            <Switch
              defaultChecked={
                Object.keys(classInitialValues).length !== 0 &&
                // @ts-expect-error - doNotMarket exists on classInitialValues if the object has keys.
                classInitialValues?.doNotMarket
              }
            />
          </Form.Item>
          <Form.Item>
            <Form.Item
              noStyle
              getValueFromEvent={getValueFromEvent}
              valuePropName="fileList"
            >
              <Upload.Dragger
                action={(args) => getUploadAction(args, 'class152x240')}
                customRequest={(args) => {
                  const formData = new FormData()
                  formData.append('File', args.file)
                  const file = formData.get('File')

                  const xhr = new XMLHttpRequest()
                  xhr.open('PUT', args.action)
                  xhr.send(file)
                }}
                listType="picture"
                maxCount={1}
                method="PUT"
                name="file"
                style={{
                  background: 'none',
                  border: 'none',
                  width: '100%',
                }}
              >
                <Space direction="vertical" size={2}>
                  <div>class152x240 image</div>
                  <Image
                    alt="Facility Location"
                    src={
                      queryResult?.data?.data.images?.class152x240 ??
                      '/images/user-default-img.png'
                    }
                    style={{
                      height: '100%',
                      maxWidth: '200px',
                      width: '100%',
                    }}
                  />
                </Space>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Form.Item
              noStyle
              getValueFromEvent={getValueFromEvent}
              valuePropName="fileList"
            >
              <Upload.Dragger
                action={(args) => getUploadAction(args, 'class240x140')}
                customRequest={(args) => {
                  const formData = new FormData()
                  formData.append('File', args.file)
                  const file = formData.get('File')

                  const xhr = new XMLHttpRequest()
                  xhr.open('PUT', args.action)
                  xhr.send(file)
                }}
                listType="picture"
                maxCount={1}
                method="PUT"
                name="file"
                style={{
                  background: 'none',
                  border: 'none',
                  width: '100%',
                }}
              >
                <Space direction="vertical" size={2}>
                  <div>class240x140 image</div>
                  <Image
                    alt="Facility Location"
                    src={
                      queryResult?.data?.data.images?.class240x140 ??
                      '/images/user-default-img.png'
                    }
                    style={{
                      height: '100%',
                      maxWidth: '200px',
                      width: '100%',
                    }}
                  />
                </Space>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Form.Item
              noStyle
              getValueFromEvent={getValueFromEvent}
              valuePropName="fileList"
            >
              <Upload.Dragger
                action={(args) => getUploadAction(args, 'class152x89')}
                customRequest={(args) => {
                  const formData = new FormData()
                  formData.append('File', args.file)
                  const file = formData.get('File')

                  const xhr = new XMLHttpRequest()
                  xhr.open('PUT', args.action)
                  xhr.send(file)
                }}
                listType="picture"
                maxCount={1}
                method="PUT"
                name="file"
                style={{
                  background: 'none',
                  border: 'none',
                  width: '100%',
                }}
              >
                <Space direction="vertical" size={2}>
                  <div>class152x89 image</div>
                  <Image
                    alt="Facility Location"
                    src={
                      queryResult?.data?.data.images?.class152x89 ??
                      '/images/user-default-img.png'
                    }
                    style={{
                      height: '100%',
                      maxWidth: '200px',
                      width: '100%',
                    }}
                  />
                </Space>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        </Form>
      </Edit>
      <Divider />
      {classData ? <CreateReservation classData={classData} /> : null}
      <Divider />
      {classData ? <CreateRecurringReservations classData={classData} /> : null}
    </>
  )
}
