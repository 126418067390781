import { Instructor } from 'interfaces'

export const getInstructorName = (instructor?: Instructor) => {
  if (!instructor) {
    return ''
  }
  return instructor?.user?.firstName || instructor?.user?.lastName
    ? [instructor?.user?.firstName, instructor?.user?.lastName]
        .filter(Boolean)
        .join(' ')
    : instructor?.user?.email || ''
}
