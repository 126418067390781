import { EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { DeleteButton, EditButton, useForm } from '@refinedev/antd'
import {
  useCreate,
  useDelete,
  useList,
  useOne,
  useShow,
  useUpdate,
} from '@refinedev/core'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import {
  Certification,
  Instructor,
  IOrganization,
  ISport,
  IUser,
} from 'interfaces'
import { useState } from 'react'

export const InstructorsShow: React.FC = () => {
  // 🪝 HOOKS
  const { queryResult } = useShow<Instructor>({
    resource: 'instructors',
  })
  const { form: formModalAdd, formProps: formModalPropsAdd } = useForm()
  const { form: formModalEdit, formProps: formModalPropsEdit } = useForm()
  const createCertification = useCreate()
  const updateCertification = useUpdate()
  const [isNewCertModalOpen, setIsNewCertModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isAddSportsModalOpen, setIsAddSportsModalOpen] = useState(false)
  const [isAddOrganizationsModalOpen, setIsAddOrganizationsModalOpen] =
    useState(false)
  const [editedCertification, setEditedCertification] =
    useState<Certification>()
  const [expirationDate, setExpirationDate] = useState<
    string | undefined | null | Date | Dayjs
  >(null)
  const {
    form: instructorSportFormModal,
    formProps: instructorSportFormModalProps,
  } = useForm<Instructor>()
  const {
    form: instructorOrganizationFormModal,
    formProps: instructorOrganizationFormModalProps,
  } = useForm<Instructor>()
  const { mutate: mutateCreate } = useCreate()
  const { mutate: mutateDelete } = useDelete()

  // 💿 DATA
  // fetch instructor data
  const { data } = queryResult
  const record = data?.data
  // fetch all sports
  const { data: sports } = useList<ISport>({ resource: 'sports' })
  // fetch all organizations
  const { data: organizations } = useList<IOrganization>({
    resource: 'organizations',
  })

  const showNewCertModal = () => {
    setExpirationDate(null)
    setIsNewCertModalOpen(true)
  }

  const handleCancelAddCert = () => {
    setIsNewCertModalOpen(false)
  }

  const handleOkAddCert = () => {
    const newCertification: Certification = formModalAdd.getFieldsValue()

    createCertification.mutate(
      {
        invalidates: ['all'],
        resource: `instructors/${record?.id}/certifications`,
        values: {
          ...newCertification,
        },
      },
      {
        onSuccess: () => {
          setExpirationDate(undefined)
          formModalAdd.resetFields()
        },
      }
    )

    setIsNewCertModalOpen(false)
  }

  // 🚀 Functions

  const connectSportInstructor = () => {
    const sportId = instructorSportFormModal.getFieldValue('selectedSportId')
    const hourlyRate = Number(
      instructorSportFormModal.getFieldValue('hourlyRate')
    )
    const instructorId = record?.id
    mutateCreate({
      resource: `instructors/${instructorId}/sports`,
      values: {
        sportId,
        hourlyRate,
      },
      invalidates: ['all'],
    })

    setIsAddSportsModalOpen(false)
  }

  const connectOrganizationInstructor = () => {
    const organizationId = instructorOrganizationFormModal.getFieldValue(
      'selectedOrganizationId'
    )
    const instructorId = record?.id
    mutateCreate({
      resource: `instructors/${instructorId}/organizations`,
      values: {
        organizationId,
      },
      invalidates: ['all'],
    })

    setIsAddOrganizationsModalOpen(false)
  }

  const disconnectSportInstructor = async (sportId: string) => {
    const instructorId = record?.id
    mutateDelete({
      resource: `instructors/${instructorId}/sports/${sportId}`,
      id: '',
      invalidates: ['all'],
    })
  }
  const disconnectOrganizationInstructor = async (organizationId: string) => {
    const instructorId = record?.id
    mutateDelete({
      resource: `instructors/${instructorId}/organizations/${organizationId}`,
      id: '',
      invalidates: ['all'],
    })
  }

  const showEditCertModal = (certification: Certification | null) => {
    if (certification) {
      setEditedCertification(certification)

      if (certification.expiresAt) {
        formModalEdit.setFieldsValue({
          description: certification.description,
          name: certification.name,
        })
      }

      formModalEdit.setFieldsValue(certification)
    }

    setIsEditModalOpen(true)
    setExpirationDate(undefined)
  }

  const handleCancelEdit = () => {
    setIsEditModalOpen(false)
    setExpirationDate(undefined)
  }

  const handleOkEditCert = () => {
    // get certification data from form
    const updatedCertification: Certification = formModalEdit.getFieldsValue()

    // update certification with useUpdate hook
    if (!editedCertification?.id) {
      setIsEditModalOpen(false)

      return
    }

    updateCertification.mutate(
      {
        id: editedCertification.id,
        resource: `instructors/${record?.id}/certifications`,
        values: {
          ...updatedCertification,
          expiresAt: expirationDate,
        },
      },
      {
        onSuccess: () => {
          queryResult.refetch()
          setExpirationDate(undefined)
          formModalEdit.resetFields()
          setIsEditModalOpen(false)
        },
      }
    )
  }

  const { data: user } = useOne<IUser>({
    id: record?.userId || '',
    queryOptions: {
      enabled: !!record?.userId,
    },
    resource: 'users',
  })

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={24} xl={6} xs={24}>
          <Card bordered={false} style={{ height: '100%' }}>
            <Space
              direction="vertical"
              style={{ height: '100%', width: '100%' }}
            >
              {/* Instructor information */}
              <Space
                direction="vertical"
                style={{ textAlign: 'left', width: '100%' }}
              >
                <h1>Instructor information</h1>
                <div>
                  <strong>
                    Name: {user?.data.firstName} {user?.data.lastName}
                  </strong>
                </div>
                <div>
                  <strong>Description:</strong>
                </div>
                <div>{record?.shortDescription}</div>
                <div>
                  <strong>Bio:</strong>
                </div>
                <div>{record?.longBio}</div>
                <div>
                  <strong>Tags:</strong>
                </div>
                <div>
                  {record?.tags?.map((tag) => (
                    <Tag>{tag}</Tag>
                  ))}
                </div>
              </Space>
              <Space>
                <EditButton recordItemId={record?.id} />
              </Space>
            </Space>
          </Card>
        </Col>
        <Col lg={24} xl={18} xs={24}>
          <Card bordered={false} style={{ height: '100%' }}>
            <Table
              dataSource={record?.certifications}
              pagination={false}
              rowKey="id"
            >
              <Table.Column dataIndex="name" title="Certification Name" />
              <Table.Column dataIndex="description" title="Description" />
              <Table.Column
                dataIndex="expiresAt"
                render={(date) =>
                  date ? new Date(date).toLocaleDateString() : 'N/A'
                }
                title="Expiration Date"
              />
              <Table.Column
                dataIndex="id"
                render={(id) => (
                  <Button
                    key="edit-certification"
                    size="small"
                    onClick={async () => {
                      // set edited certification
                      const editedCertification = record?.certifications.find(
                        (cert) => cert.id === id
                      )

                      // set expxiration date to current value
                      setExpirationDate(dayjs(editedCertification?.expiresAt))

                      showEditCertModal(
                        editedCertification ? editedCertification : null
                      )
                    }}
                  >
                    <EditOutlined /> Edit
                  </Button>
                )}
                title="Actions"
              />
            </Table>

            <Button
              key="add-certification"
              size="small"
              type="primary"
              onClick={showNewCertModal}
            >
              +Add Certification
            </Button>
            {/* MODAL to add new certification */}
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col lg={24} xl={24} xs={24}>
          <Typography.Title
            style={{
              marginTop: '20px',
            }}
            level={4}
          >
            Instructor's organizations:
          </Typography.Title>
          <Button
            type="primary"
            style={{
              marginRight: '20px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
            onClick={() => {
              setIsAddOrganizationsModalOpen(true)
            }}
          >
            <PlusOutlined />
            Add new organization
          </Button>
          <List>
            <Table dataSource={record?.organizations} rowKey="id">
              <Table.Column title="Name" dataIndex="name" key="name" />
              <Table.Column
                dataIndex="id"
                key="disconnect"
                render={(_, record: IOrganization) => {
                  return (
                    <Button
                      danger
                      onClick={() => {
                        disconnectOrganizationInstructor(record.id)
                      }}
                      size="small"
                    >
                      Disconnect
                    </Button>
                  )
                }}
                title="Disconnect"
              />
            </Table>
          </List>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col lg={24} xl={24} xs={24}>
          <Typography.Title
            style={{
              marginTop: '20px',
            }}
            level={4}
          >
            Instructor's sports:
          </Typography.Title>
          <Button
            type="primary"
            style={{
              marginRight: '20px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
            onClick={() => {
              setIsAddSportsModalOpen(true)
            }}
          >
            <PlusOutlined />
            Add new sport{' '}
          </Button>
          <List>
            <Table dataSource={record?.sports} rowKey="id">
              <Table.Column title="Name" dataIndex="name" key="name" />
              <Table.Column
                dataIndex="description"
                key="description"
                title="Description"
              />
              <Table.Column
                dataIndex="hourlyRate"
                key="hourlyRate"
                title="Hourly Rate"
              />
              <Table.Column
                dataIndex="id"
                key="disconnect"
                render={(_, record: ISport) => {
                  return (
                    <Button
                      danger
                      onClick={() => {
                        disconnectSportInstructor(record.id)
                      }}
                      size="small"
                    >
                      Disconnect
                    </Button>
                  )
                }}
                title="Disconnect"
              />
            </Table>
          </List>
        </Col>
      </Row>

      <Modal
        open={isNewCertModalOpen}
        title="Add Certification"
        onCancel={handleCancelAddCert}
        onOk={handleOkAddCert}
      >
        <Form layout="vertical" {...formModalPropsAdd}>
          <Form.Item required label="Name" name="name">
            <Input.TextArea />
          </Form.Item>
          <Form.Item required label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Expiration date" name="expiresAt">
            <DatePicker
              dateRender={(current) => {
                const style: React.CSSProperties = {}

                if (current.date() === 1) {
                  style.border = '1px solid #1890ff'
                  style.borderRadius = '50%'
                }

                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                )
              }}
              placeholder="Select expiration date"
              onChange={setExpirationDate}
            />
          </Form.Item>
        </Form>
      </Modal>
      {/* MODAL to edit/delete a certification */}
      <Modal
        footer={[
          <>
            <DeleteButton
              recordItemId={editedCertification?.id}
              resourceNameOrRouteName={`instructors/${record?.id}/certifications`}
              onSuccess={() => {
                queryResult.refetch()
                setEditedCertification(undefined)
                setIsEditModalOpen(false)
              }}
            />

            <Button
              key="edit-certification"
              size="small"
              type="primary"
              onClick={handleOkEditCert}
            >
              <SaveOutlined /> Save
            </Button>
          </>,
        ]}
        open={isEditModalOpen}
        title="Edit Certification"
        onCancel={handleCancelEdit}
      >
        <Form layout="vertical" {...formModalPropsEdit}>
          <Form.Item required label="Name" name="name">
            <Input.TextArea />
          </Form.Item>
          <Form.Item required label="Description" name="description">
            <Input.TextArea />
          </Form.Item>

          <DatePicker
            allowClear
            dateRender={(current) => {
              const style: React.CSSProperties = {}

              if (current.date() === 1) {
                style.border = '1px solid #1890ff'
                style.borderRadius = '50%'
              }

              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              )
            }}
            placeholder="Select expiration date"
            onChange={setExpirationDate}
          />
        </Form>
      </Modal>
      {/* MODAL to add sport to an instructor */}
      <Modal
        width={800}
        title="Connect Sport with this Instructor"
        open={isAddSportsModalOpen}
        onOk={connectSportInstructor}
        onCancel={() => {
          setIsAddSportsModalOpen(false)
        }}
      >
        <Typography.Paragraph>Select a sport</Typography.Paragraph>
        <Form layout="vertical" {...instructorSportFormModalProps}>
          <Form.Item required label="Sport" name="selectedSportId">
            <Select
              placeholder="Select a sport"
              title="Select a group"
              style={{ width: 400 }}
            >
              {sports?.data.map((sport) => (
                <Select.Option key={sport.id} value={sport.id}>
                  {sport.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            required
            label="Hourly Rate"
            name="hourlyRate"
            style={{ width: 400 }}
          >
            <Input type="number" />
          </Form.Item>
        </Form>
      </Modal>
      {/* MODAL to add organization to an instructor */}
      <Modal
        width={800}
        title="Connect Organization with this Instructor"
        open={isAddOrganizationsModalOpen}
        onOk={connectOrganizationInstructor}
        onCancel={() => {
          setIsAddOrganizationsModalOpen(false)
        }}
      >
        <Typography.Paragraph>Select an organization</Typography.Paragraph>
        <Form layout="vertical" {...instructorOrganizationFormModalProps}>
          <Form.Item
            required
            label="Organization"
            name="selectedOrganizationId"
          >
            <Select
              placeholder="Select an organization"
              title="Select an organization"
              style={{ width: 400 }}
            >
              {organizations?.data.filter(
                  item => !record?.organizations?.some(org => org.id === item.id)
              ).map((organization) => (
                <Select.Option key={organization.id} value={organization.id}>
                  {organization.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
