import { PlusOutlined, UsergroupDeleteOutlined } from '@ant-design/icons'
import { Breadcrumb, Show, ShowButton, useForm } from '@refinedev/antd'
import {
  IResourceComponentsProps,
  useCreate,
  useDelete,
  useList,
  useOne,
  useShow,
  useUpdate,
} from '@refinedev/core'
import {
  Button,
  Card,
  Col,
  Form,
  List,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from 'antd'

import type { IGroup, IMembership, IRuleResponse, IUser } from 'interfaces'
import React, { useState } from 'react'

export const GroupsShow: React.FC<IResourceComponentsProps> = () => {
  // 🪝 HOOKS
  const data = useShow<IGroup>()
  const [isAddRulesModalOpen, setIsAddRulesModalOpen] = useState(false)
  const [isAddUsersModalOpen, setIsAddUsersModalOpen] = useState(false)
  const { mutate: mutateCreate } = useCreate()
  const { mutate: mutateDelete } = useDelete()
  const { mutate: mutateEdit } = useUpdate()

  const { form: groupRuleformModal, formProps: groupRuleformModalProps } =
    useForm<IRuleResponse>()

  const [membershipsToDelete, setMembershipsToDelete] = React.useState<
    React.Key[]
  >([])

  const [userToAdd, setUserToAdd] = React.useState<React.Key[]>([])

  // 💿 DATA
  const record = data?.queryResult?.data?.data

  const { data: location } = useOne<IGroup>({
    id: record?.id || '',
    queryOptions: {
      enabled: !!record?.id,
    },
    resource: 'groups',
  })

  // fetch all the rules
  const { data: rules } = useList<IRuleResponse>({
    resource: 'rules',
  })

  // fetch members of the group
  const { data: memberships } = useList<IMembership>({
    resource: `groups/${record?.id}/memberships`,
  })
  // get ids of all users in the group
  const idsOfMembers: string[] = []
  memberships?.data?.map((membership) => idsOfMembers.push(membership.userId))

  // fetch all the users that are not in the group

  const { data: allUsers } = useList<IUser>({
    resource: 'users',
    queryOptions: {
      enabled: !!record?.id,
    },
    pagination: {
      pageSize: 10000,
      mode: 'server',
    },
  })

  // display only users that are not in the group in the select
  const usersNotInGroup = allUsers?.data?.filter(
    (user) => !idsOfMembers.includes(user.id)
  )

  // 🧰 FUNCTIONS

  // Managing Group-Rule connection
  const showAddRulesModal = () => {
    setIsAddRulesModalOpen(true)
  }

  const connectGroupRule = () => {
    const newRuleId: { ruleId: string } = {
      ruleId: groupRuleformModal.getFieldValue([]).selectedRuleId,
    }
    mutateCreate({
      resource: `groups/${record?.id}/rules`,
      values: {
        ...newRuleId,
      },
      invalidates: ['all'],
    })

    setIsAddRulesModalOpen(false)
  }

  const handleCancelRulesModal = () => {
    setIsAddRulesModalOpen(false)
  }

  const deleteGroupRule = (id: string) => {
    mutateDelete({
      resource: `groups/${record?.id}/rules/${id}`,
      id: '',
      invalidates: ['all'],
    })
  }

  // Managing Group-User (memberships) connection
  // Removing users from group
  const onSelectChangeDeleteMemberships = (selectedRowKeys: React.Key[]) => {
    setMembershipsToDelete(selectedRowKeys)
  }

  const rowSelectionMembershipsToDelete = {
    membershipsToDelete,
    onChange: onSelectChangeDeleteMemberships,
  }

  const removeUsersFromGroup = () => {
    mutateEdit({
      resource: `groups/${record?.id}/memberships`,
      id: '',
      invalidates: ['all'],
      meta: {
        httpMethod: 'put',
      },
      values: {
        membershipIds: membershipsToDelete,
      },
    })
  }

  // Adding users to group
  const showAddUsersModal = () => {
    setIsAddUsersModalOpen(true)
  }

  const handleCancelUsersModal = () => {
    setIsAddUsersModalOpen(false)
  }

  const onSelectChangeAddUsers = (selectedRowKeys: React.Key[]) => {
    setUserToAdd(selectedRowKeys)
  }

  const rowSelectionUsersToAdd = {
    userToAdd,
    onChange: onSelectChangeAddUsers,
  }

  const addUsersToGroup = () => {
    mutateCreate({
      resource: `groups/${record?.id}/memberships`,
      values: {
        userIds: userToAdd,
      },
      invalidates: ['all'],
    })
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={24} xl={12} xs={24}>
          <Card>
            <Show breadcrumb={<Breadcrumb />} resource="groups">
              <Typography.Title level={3}>{record?.name}</Typography.Title>
              <Typography.Paragraph italic>
                Group Description:
              </Typography.Paragraph>
              <Typography.Paragraph>{record?.description}</Typography.Paragraph>
              <Typography.Paragraph italic>
                Group Eligibility:
              </Typography.Paragraph>
              <Typography.Paragraph>
                {location?.data.eligibility}
              </Typography.Paragraph>
              <Typography.Paragraph italic>Group Type:</Typography.Paragraph>
              <Typography.Paragraph>
                {location?.data.groupType}
              </Typography.Paragraph>
              <Typography.Paragraph italic>
                Join Instructions:
              </Typography.Paragraph>
              <Typography.Paragraph>
                {location?.data.joinInstructions}
              </Typography.Paragraph>
              <Typography.Paragraph italic>Benefits:</Typography.Paragraph>
              <Typography.Paragraph>
                {location?.data.benefit}
              </Typography.Paragraph>
              <Typography.Paragraph italic>Organization:</Typography.Paragraph>
              <Typography.Paragraph>
                {record?.organization?.name}
              </Typography.Paragraph>
            </Show>
          </Card>
        </Col>
        <Col lg={24} xl={12} xs={24}>
          <Card>
            <Typography.Title level={4}>Connected Rules:</Typography.Title>
            <Button
              style={{ marginBottom: '20px', marginTop: '10px' }}
              type="primary"
              onClick={showAddRulesModal}
            >
              <PlusOutlined />
              Connect new rule to this group{' '}
            </Button>

            <Table dataSource={record?.rules}>
              <Table.Column title="Name" dataIndex="name" />
              <Table.Column
                title="Reservation Type"
                dataIndex="reservationType"
              />
              <Table.Column
                dataIndex="id"
                key="show"
                render={(_, record: IRuleResponse) => {
                  return (
                    <ShowButton
                      hideText
                      id={record.id}
                      recordItemId={record.id}
                      resource="rules"
                      size="small"
                    />
                  )
                }}
                title="Detail"
              />
              <Table.Column
                dataIndex="id"
                key="disconnect"
                render={(_, record: IRuleResponse) => {
                  return (
                    <Button
                      danger
                      onClick={() => {
                        deleteGroupRule(record.id)
                      }}
                      size="small"
                    >
                      Disconnect
                    </Button>
                  )
                }}
                title="Disconnect"
              />
            </Table>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 26]}>
        <Col lg={24} xl={24} xs={24}>
          <Typography.Title
            style={{
              marginTop: '20px',
            }}
            level={4}
          >
            Users in this group:
          </Typography.Title>
          <Button
            type="primary"
            style={{
              marginRight: '20px',
              marginBottom: '20px',
              marginTop: '10px',
            }}
            onClick={showAddUsersModal}
          >
            <PlusOutlined />
            Add users to this group{' '}
          </Button>
          {membershipsToDelete.length > 0 ? (
            <Button danger onClick={removeUsersFromGroup}>
              {' '}
              <UsergroupDeleteOutlined />
              Remove users{' '}
            </Button>
          ) : (
            <Button disabled>
              {' '}
              <UsergroupDeleteOutlined />
              Remove users{' '}
            </Button>
          )}
          <List>
            <Table
              dataSource={memberships?.data.map((membership) => ({
                key: membership.id,
                firstName: membership.user.firstName,
                lastName: membership.user.lastName,
                email: membership.user.email,
                userId: membership.user.id,
                // Add more properties as needed
              }))}
              rowSelection={rowSelectionMembershipsToDelete}
              rowKey="key"
            >
              <Table.Column title="Name" dataIndex="firstName" />
              <Table.Column title="Last Name" dataIndex="lastName" />
              <Table.Column title="Email" dataIndex="email" />
              <Table.Column
                dataIndex="id"
                key="show"
                render={(_, record: IMembership) => {
                  return (
                    <ShowButton
                      hideText
                      id={record.userId}
                      recordItemId={record.userId}
                      resource="users"
                      size="small"
                    />
                  )
                }}
                title="Detail"
              />
            </Table>
          </List>
        </Col>
      </Row>
      <Modal
        title="Select a rule to connect to this group"
        open={isAddRulesModalOpen}
        onOk={connectGroupRule}
        onCancel={handleCancelRulesModal}
      >
        <Form layout="vertical" {...groupRuleformModalProps}>
          <Form.Item required label="Rule" name="selectedRuleId">
            <Select
              style={{
                minWidth: 200,
              }}
              placeholder="Select a rule"
            >
              {rules?.data?.map((rule) => {
                return (
                  <Select.Option key={rule.id} value={rule.id}>
                    {rule.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Select a users to add to this group"
        width={1000}
        open={isAddUsersModalOpen}
        onOk={addUsersToGroup}
        onCancel={handleCancelUsersModal}
      >
        <List>
          <Table
            dataSource={usersNotInGroup?.map((user) => ({
              key: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              id: user.id,
              // Add more properties as needed
            }))}
            rowSelection={rowSelectionUsersToAdd}
            rowKey="key"
          >
            <Table.Column title="Name" dataIndex="firstName" />
            <Table.Column title="Last Name" dataIndex="lastName" />
            <Table.Column title="Email" dataIndex="email" />
            <Table.Column
              dataIndex="key"
              key="show"
              render={(_, record: IUser) => {
                return (
                  <ShowButton
                    hideText
                    id={record.id}
                    recordItemId={record.id}
                    resource="users"
                    size="small"
                  />
                )
              }}
              title="Detail"
            />
          </Table>
        </List>
      </Modal>
    </>
  )
}
