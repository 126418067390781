import { PlusOutlined } from '@ant-design/icons'
import { useForm } from '@refinedev/antd'
import { useCreate, useList } from '@refinedev/core'
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
} from 'antd'
import type { FC } from 'react'

import type { ICourtPostRequest, ISport } from 'interfaces'

import { CourtSlotType } from 'utils/enums'
import { dayOfWeekOptions, timeOptions } from './data'

interface ICreateCourtModalProps {
  courtsLength?: number
  isModalOpen: boolean
  locationId?: string
  setIsModalOpen: (value: boolean) => void
}

export const CreateCourtModal: FC<ICreateCourtModalProps> = ({
  courtsLength,
  isModalOpen,
  locationId,
  setIsModalOpen,
}) => {
  const { form, formProps } = useForm<ICourtPostRequest>()
  const { mutate } = useCreate<ICourtPostRequest>({})

  // fetch all sports:
  const { data: sportsData } = useList<ISport>({
    resource: 'sports',
  })

  const handleOk = (values: any) => {
    const sanitizedValues: Record<string, any> = {
      courtNumber: values.courtNumber,
      locationId: values.locationId,
      maxReservationTime: values.maxReservationTime,
      defaultReservationWindowDays: values.defaultReservationWindowDays,
      slots: values.slots,
      sports: [{ sportId: values.sportId, price: Number(values.price) }],
      noReservationText: values.noReservationText,
      allowPrivateLessons: values.allowPrivateLessons,
    }

    if (values.reservationReleaseTimeLocal) {
      sanitizedValues.reservationReleaseTimeLocal =
        values.reservationReleaseTimeLocal
    }

    mutate(
      {
        invalidates: ['all'],
        resource: 'courts',
        values: sanitizedValues,
      },
      {
        onSuccess: () => {
          setIsModalOpen(false)
        },
      }
    )
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal
      open={isModalOpen}
      title="Add Court"
      onCancel={handleCancel}
      onOk={form.submit}
      width={600}
    >
      <Form
        {...formProps}
        initialValues={{
          courtNumber: courtsLength ? (courtsLength + 1).toString() : '1',
          locationId: locationId,
          maxReservationTime: '02:00:00',
          slots: [
            {
              dayOfWeek: 1,
              openFrom: '08:00:00',
              openTo: '22:00:00',
              type: CourtSlotType.PRIVATE,
            },
          ],
          sports: [],
        }}
        layout="vertical"
        onFinish={handleOk}
      >
        <Form.Item
          hidden
          label="Location Id"
          name="locationId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Court Number"
          name="courtNumber"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Max Reservation Time"
          name="maxReservationTime"
          rules={[
            {
              message: 'Please use HH:MM:SS format (e.g. 01:30:45)',
              pattern: /^\d{2}:\d{2}:\d{2}$/,
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Reservation Window Override (days)"
          name="defaultReservationWindowDays"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item
          label="Reservation Release Time Override"
          name="reservationReleaseTimeLocal"
          rules={[
            {
              message:
                'Invalid input. Must use 24-hour HH:MM:SS format (e.g. 13:30:00 for 1:30 PM)',
              pattern: /^\d{2}:\d{2}:\d{2}$/,
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="No reservation text" name="noReservationText">
          <Input />
        </Form.Item>
        <Form.Item label="Allow Private Lessons" name="allowPrivateLessons">
          <Switch />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Sport" name={['sportId']}>
              <Select>
                {sportsData?.data.map((sport) => {
                  return (
                    <Select.Option key={sport.id} value={sport.id}>
                      {sport.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Price" name={['price']}>
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="slots">
          {(slotFields, { add, remove }) => {
            return (
              <Space direction="vertical">
                {slotFields.map((slotField) => {
                  return (
                    <Space align="center" key={slotField.key}>
                      <Form.Item
                        {...slotField}
                        label="Day of week"
                        name={[slotField.key, 'dayOfWeek']}
                      >
                        <Select options={dayOfWeekOptions} />
                      </Form.Item>
                      <Form.Item
                        {...slotField}
                        label="Open from"
                        name={[slotField.key, 'openFrom']}
                      >
                        <Select options={timeOptions} />
                      </Form.Item>
                      <Form.Item
                        {...slotField}
                        label="Open to"
                        name={[slotField.key, 'openTo']}
                      >
                        <Select options={timeOptions} />
                      </Form.Item>
                      <Form.Item
                        {...slotField}
                        label="Type"
                        name={[slotField.key, 'type']}
                      >
                        <Select
                          options={[
                            {
                              label: CourtSlotType.INSTRUCTOR,
                              value: CourtSlotType.INSTRUCTOR,
                            },
                            {
                              label: CourtSlotType.PRIVATE,
                              value: CourtSlotType.PRIVATE,
                            },
                          ]}
                          defaultValue={CourtSlotType.PRIVATE}
                        />
                      </Form.Item>
                      <Button
                        danger
                        size="small"
                        onClick={() => remove(slotField.name)}
                      >
                        Remove slot
                      </Button>
                    </Space>
                  )
                })}
                <Form.Item>
                  <Button
                    block
                    icon={<PlusOutlined />}
                    type="dashed"
                    onClick={() =>
                      add({
                        dayOfWeek: 1,
                        openFrom: '08:00:00',
                        openTo: '22:00:00',
                        type: CourtSlotType.PRIVATE,
                      })
                    }
                  >
                    Add slot
                  </Button>
                </Form.Item>
              </Space>
            )
          }}
        </Form.List>
      </Form>
    </Modal>
  )
}
