import React from 'react'
import { Show } from '@refinedev/antd'
import { IOrganization, IOrganizationLocation } from '../../interfaces'
import { useRouterContext, useShow } from '@refinedev/core'
import { Tag, Typography } from 'antd'

export const OrganizationsShow: React.FC = () => {
  const { queryResult } = useShow<IOrganization>({ resource: 'organizations' })
  const { Link } = useRouterContext()
  const record = queryResult?.data?.data

  return (
    <Show resource="organizations">
      {record?.logo && (
        <img
          src={record?.logo}
          width={200}
          style={{
            marginBottom: '20px',
          }}
        ></img>
      )}
      <Typography.Title level={3}>{record?.name}</Typography.Title>
      <Typography.Paragraph>
        {record?.locations.map((location: IOrganizationLocation) => (
          <Tag color="blue" key={location.id}>
            {location.name}
          </Tag>
        ))}
      </Typography.Paragraph>
      <Typography.Paragraph>Name: {record?.name}</Typography.Paragraph>
      <Typography.Paragraph>
        Description: {record?.description}
      </Typography.Paragraph>
      <Typography.Paragraph>Slug: {record?.slug}</Typography.Paragraph>
      <Typography.Paragraph>
        Section Waitlist Type: {record?.sectionWaitlistType ?? 'default'}
      </Typography.Paragraph>
    </Show>
  )
}
